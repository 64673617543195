import { useEffect, useState } from "react";
import {
  addReview,
  getFilmmaker,
  getFollowers,
  getFollowing,
  getGpt,
  getMoreVideosFromFilmmaker,
  getVideosFromFilmmaker,
  toggleEndorsement,
  toggleFollow,
} from "../firestore";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { getNiceDataString } from "../utils/strings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import {
  faChevronUp,
  faCircleInfo,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import GPTCard from "../components/GPTCard";
import ProfilePicture from "../components/ProfilePicture";
import { tools } from "../utils/tools";
import ToolCard from "../components/ToolCard";

export default function Filmmaker({ user }) {
  const [id, setId] = useState();
  const [profile, setProfile] = useState();
  const [skills, setSkills] = useState();
  const [selectedTools, setSelectedTools] = useState([]);
  const [followers, setFollowers] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [toolbox, setToolbox] = useState([]);
  const [active, setActive] = useState("Followers");
  const [fetchedFollowers, setFetchedFollowers] = useState([]);
  const [fetchedFollowing, setFetchedFollowing] = useState([]);
  const [review, setReview] = useState("");
  const [videos, setVideos] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [clipboard, setClipboard] = useState();

  console.log("reviews: ", reviews);

  useEffect(() => {
    window.scrollTo(0, 0);
    setToolbox(tools);
    let url = window.location.href;
    setId(url.split("/")[url.split("/").length - 1]);
  }, []);

  function handleLoadMore() {
    setCurrentPage((old) => old + 1);
  }

  useEffect(() => {
    if (currentPage) {
      getMoreVideosFromFilmmaker(id, 4).then((res) =>
        setVideos((old) => old.concat(res))
      );
    }
  }, [currentPage]);

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function handleOpen(input) {
    setActive(input);

    if (input == "Followers") {
      console.log("getting followers");
      getFollowers(profile?.data?.followers).then((res) => {
        console.log(res);
        setFetchedFollowers(res);
      });
    }

    if (input == "Following") {
      console.log("getting following");
      getFollowing(profile?.data?.following).then((res) => {
        console.log(res);
        setFetchedFollowing(res);
      });
    }

    setIsOpen(true);
  }

  async function copyToClipboard() {
    await navigator.clipboard.writeText(window.location.href);
    setClipboard(true);
  }

  function submitReview() {
    console.log("Submitted review!");
    if (review.length > 0) {
      addReview(user, profile, review).then((res) => {
        const tmp = [...res];
        setReview("");
        setReviews(tmp);
      });
    }
  }

  useEffect(() => {
    getFilmmaker(id).then((res) => {
      if (res) {
        setProfile(res[0]);
        setReviews(res[0].data.reviews);
        setSkills(res[0].data.skills);
        setFollowers(res[0].data.followers);
        setSelectedTools(res[0].data.selectedTools);
        getVideosFromFilmmaker(id, 6).then((res) => {
          setVideos(res);
        });
      }
    });
  }, [id]);

  const handleEndorsement = (skill) => {
    try {
      let tmpSkills = skills;
      let endorsers = tmpSkills[skill];
      let userHasEndorsed = endorsers.includes(user?.id);

      toggleEndorsement(skill, profile.id, user.id, userHasEndorsed).then(
        (result) => {
          setSkills(result);
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleLink = (url) => {
    window.location.href = url;
  };

  const handleFollow = () => {
    try {
      let userHasFollowed = followers.includes(user?.id);

      toggleFollow(profile.id, user?.id, userHasFollowed).then((result) => {
        setFollowers(result);
      });
    } catch (e) {
      console.error(e);
    }
  };

  console.log("skills: ", skills);

  function generateSocials() {
    return (
      <div className="flex gap-6 mt-2">
        {profile.data.instagram.length > 0 ? (
          <a
            className="h-12 w-8 text-center text-gray hover:text-white"
            href={profile.data.instagram}
            target="_blank"
          >
            <FontAwesomeIcon icon={faInstagram} className="text-3xl mt-2 " />{" "}
          </a>
        ) : null}

        {profile.data.youtube.length > 0 ? (
          <a
            className="h-12 w-8 text-center text-gray hover:text-red-500"
            href={profile.data.youtube}
            target="_blank"
          >
            <FontAwesomeIcon icon={faYoutube} className="text-3xl mt-2 " />{" "}
          </a>
        ) : null}

        {profile.data.twitter.length > 0 ? (
          <a
            className="h-12 w-8 text-center text-gray hover:text-blue-400"
            href={profile.data.twitter}
            target="_blank"
          >
            <FontAwesomeIcon icon={faTwitter} className="text-3xl mt-2 " />{" "}
          </a>
        ) : null}
      </div>
    );
  }

  if (profile == null) {
    return (
      <>
        <div className="bg-black h-screen">
          <h1 className="text-center text-xl pt-20 text-white font-semibold">
            Loading...
          </h1>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="w-11/12 mx-auto h-full py-12 grid grid-cols-12 gap-4">
          <div className="xl:col-span-3 col-span-12 bg-darkGray p-4 pt-8 rounded-xl h-fit">
            <ProfilePicture user={id} center={true} />

            <h1 className="text-white text-3xl font-semibold mb-2 text-center mt-4 whitespace-normal break-all ">
              {profile.data.username}
            </h1>

            <div className="grid grid-cols-3 gap-4 mt-4">
              <div className="text-gray text-md text-center">
                <h1 className="font-semibold text-white text-3xl">
                  {profile.data.videos_count}
                </h1>{" "}
                <span>videos</span>
              </div>

              <button
                className="text-gray text-md"
                onClick={() => handleOpen("Following")}
              >
                <h1 className="font-semibold text-white text-3xl">
                  {profile?.data?.following.length}
                </h1>{" "}
                <span className="hover:underline">following</span>
              </button>

              <button
                className="text-gray text-md"
                onClick={() => handleOpen("Followers")}
              >
                <h1 className="font-semibold text-white text-3xl">
                  {followers.length}
                </h1>{" "}
                <span className="hover:underline">followers</span>
              </button>
            </div>

            {user ? (
              profile.id !== user?.id ? (
                <button
                  className={`py-3 px-6 text-md rounded-lg font-semibold mt-4 w-full ${
                    !followers.includes(user?.id)
                      ? "bg-primary text-white"
                      : "bg-white text-black"
                  }`}
                  onClick={() => handleFollow()}
                >
                  {followers.includes(user?.id) ? "Following" : "Follow"}
                </button>
              ) : null
            ) : (
              <div
                className="py-3 px-6 text-md rounded-lg font-semibold mt-4 w-full bg-primary text-white text-center cursor-pointer"
                onClick={() => handleLink("/sign-up")}
              >
                Follow
              </div>
            )}

            <button
              className="py-3 px-3 text-md rounded-lg font-semibold mt-4 w-full bg-white text-darkGray"
              onClick={() => copyToClipboard()}
            >
              {clipboard ? "URL copied to clipboard" : "Share profile"}
            </button>

            {profile.data.createdByAdmin ? (
              <div className="border border-dashed border-white border-opacity-30 bg-darkGray bg-opacity-80 rounded-lg p-2 mt-6 text-gray text-sm">
                This profile was created by the Nolanz team. Is it yours?{" "}
                <Link
                  to="/claim-account"
                  className="text-white hover:underline"
                >
                  Claim it
                </Link>
              </div>
            ) : null}
          </div>

          <div className="xl:col-span-9 col-span-12">
            <div className="p-4 bg-darkGray rounded-xl">
              <h1 className="text-white text-xl font-semibold mb-1">About</h1>

              {profile.data.bio ? (
                <>
                  <p className="text-gray text-md">{profile?.data?.bio}</p>
                </>
              ) : (
                <p className="text-gray text-lg pb-4 ">
                  {profile.data.username} has not added a bio yet.
                </p>
              )}

              {profile.data.instagram ||
              profile.data.youtube ||
              profile.data.twitter ? (
                <> {generateSocials()}</>
              ) : null}
            </div>

            <div className="bg-darkGray p-4 rounded-xl mt-4">
              <h1 className="text-white text-xl font-semibold mb-4">
                Primary tools
              </h1>

              <div>
                <div className="flex flex-wrap gap-3 mt-3">
                  {selectedTools?.length > 0 ? (
                    toolbox
                      .filter((a) => selectedTools?.includes(a.id))
                      .map((tool, i) => (
                        <Link
                          to={`/tools/${tool?.url}`}
                          className="bg-darkGray rounded-xl h-10 gap-3 items-center pl-2 pr-4 border-2 border-white border-opacity-10 text-left flex justify-start hover:border-primary"
                        >
                          {tool.logo ? (
                            <img
                              src={tool?.logo}
                              className="w-6 h-6 rounded-full"
                            />
                          ) : (
                            <div className="w-6 h-6 aspect-square bg-white bg-opacity-20 border-white border-opacity-10 rounded-full" />
                          )}

                          <h1 className="text-white">{tool?.title}</h1>
                        </Link>
                      ))
                  ) : (
                    <p className="text-gray text-lg pb-4 ">
                      {profile.data.username} has not selected any primary tools
                      yet.
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="bg-darkGray p-4 rounded-xl mt-4">
              <h1 className="text-white text-xl font-semibold mb-4">
                Specialties
              </h1>
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-6">
                {skills ? (
                  <>
                    {Object.keys(skills)
                      .sort((a, b) => {
                        return skills[b].length - skills[a].length;
                      })
                      .map((key) => {
                        return (
                          <div className="w-full border-b border-white border-opacity-10 flex justify-between items-center">
                            <div key={key} className="pt-2 pb-4">
                              <h1 className="text-md text-white">{key}</h1>
                              <p className="text-gray text-sm">
                                <span className="font-semibold text-white">
                                  {skills[key].length}
                                </span>{" "}
                                endorsement
                                {skills[key].length !== 1 ? "s" : ""}
                              </p>
                            </div>

                            {user ? (
                              profile.id !== user?.id ? (
                                <button
                                  className={`px-3 py-1 border-white border border-opacity-20 h-10 text-white font-semibold rounded-md text-xs  ${
                                    skills[key].includes(user?.id)
                                      ? "bg-white bg-opacity-20"
                                      : "bg-transparent hover:bg-opacity-20 hover:bg-white"
                                  }`}
                                  onClick={() => handleEndorsement(key)}
                                >
                                  {skills[key].includes(user?.id)
                                    ? "Endorsed"
                                    : "Endorse"}
                                </button>
                              ) : null
                            ) : (
                              <Link
                                className="px-3 py-2.5 border-white border border-opacity-20 h-10 text-white font-semibold rounded-md text-xs bg-transparent hover:bg-opacity-20 hover:bg-white"
                                to="/sign-up"
                              >
                                Endorse
                              </Link>
                            )}
                          </div>
                        );
                      })}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="bg-darkGray p-4 rounded-xl mt-4">
              <h1 className="text-white text-xl font-semibold mb-4">Reviews</h1>

              {profile.id !== user?.id ? (
                <div className="mb-6">
                  <textarea
                    className="w-full p-3 rounded-lg focus:outline-none bg-transparent border  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                    placeholder={`Tell others about ${profile.data.username} as an AI filmmaker - what makes them stand out?`}
                    rows={3}
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    autoComplete="off"
                  />

                  <button
                    className="cursor-pointer px-5 py-2 border border-transparent font-semibold rounded-lg text-darkGray bg-white text-sm transform ease-in duration-100 mt-2"
                    onClick={() =>
                      user ? submitReview() : handleLink("/sign-up")
                    }
                  >
                    Add review
                  </button>
                </div>
              ) : null}

              {reviews.length > 0 ? (
                [...reviews].reverse().map((review) => {
                  return (
                    <div className="text-white border-b border-white border-opacity-40 pb-3 pt-4 last:border-none">
                      <div className="flex gap-4 items-center">
                        <div className="mt-1 scale-125">
                          <ProfilePicture user={review.userId} size="small" />
                        </div>
                        <div>
                          <div
                            className="font-semibold tracking-wider text-md hover:underline cursor-pointer"
                            onClick={() =>
                              handleLink(`/filmmakers/${review?.userId}`)
                            }
                          >
                            {review?.username}
                          </div>
                          <p className="text-xs text-gray">
                            {review.submittedAt
                              ? "Submitted " +
                                getNiceDataString(review.submittedAt) +
                                " ago"
                              : ""}
                          </p>
                        </div>
                      </div>
                      <p className="text-md mt-2 text-gray italic ">
                        {review?.text}
                      </p>
                    </div>
                  );
                })
              ) : (
                <p className="text-lg text-gray">
                  {profile.data.username} has not received any reviews yet.
                </p>
              )}
            </div>

            <div className="bg-darkGray p-4 mt-4 rounded-xl">
              <h1 className="text-white text-xl font-semibold">Videos</h1>

              {videos.length == 0 ? (
                <p className="text-gray text-lg pb-4 ">
                  {profile.data.username} has not posted any videos yet.
                </p>
              ) : (
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 pb-8">
                  {videos.map((gpt, i) => (
                    <GPTCard i={i} gpt={gpt} key={i} />
                  ))}
                </div>
              )}

              {videos?.length > 0 && videos?.length % 6 === 0 ? (
                <div className="mt-16 w-full mx-auto text-center">
                  <button
                    onClick={() => handleLoadMore()}
                    className="text-center justify-center transition duration-150 cursor-pointer text-lg  rounded-full  bg-white bg-opacity-20 text-white font-semibold px-6 py-3"
                  >
                    Load more
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-60"
              leave="ease-in duration-200"
              leaveFrom="opacity-60"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-darkGray border-2 border-white border-opacity-10 px-6 pb-16 pt-20 align-middle  transition-all text-center">
                    <button
                      className="fixed top-6 right-6 h-10 w-10 rounded-full bg-white text-white hover:text-red-500 bg-opacity-30 hover:bg-opacity-100"
                      onClick={() => closeModal()}
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="text-xl mt-1.5 text-center"
                      />
                    </button>

                    <div className="flex justify-between text-xl text-white">
                      <div
                        onClick={() => handleOpen("Following")}
                        className={`cursor-pointer border-b-2 text-center mx-auto w-full p-2 ${
                          active == "Following"
                            ? "border-primary text-primary"
                            : "border-gray text-gray"
                        }`}
                      >
                        Following
                      </div>
                      <div
                        onClick={() => handleOpen("Followers")}
                        className={`cursor-pointer border-b-2 text-center mx-auto w-full p-2 ${
                          active == "Followers"
                            ? "border-primary text-primary"
                            : "border-gray text-gray"
                        }`}
                      >
                        Followers
                      </div>
                    </div>

                    <div className="h-96 overflow-y-auto mt-6">
                      {active == "Following"
                        ? fetchedFollowing?.reverse().map((f) => {
                            return (
                              <div
                                className="cursor-pointer flex gap-2 mb-6 items-center"
                                onClick={() =>
                                  handleLink(`/filmmakers/${f.id}`)
                                }
                              >
                                <ProfilePicture user={f.id} size="small" />
                                <p className="text-lg font-semibold text-gray">
                                  {f.data?.username}
                                </p>
                              </div>
                            );
                          })
                        : fetchedFollowers?.reverse().map((f) => {
                            return (
                              <div
                                className="cursor-pointer flex gap-2 mb-6 items-center"
                                onClick={() =>
                                  handleLink(`/filmmakers/${f.id}`)
                                }
                              >
                                <ProfilePicture user={f.id} size="small" />
                                <p className="text-lg font-semibold text-gray">
                                  {f.data?.username}
                                </p>
                              </div>
                            );
                          })}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  }
}
