import { addComment } from "../firestore";
import { signInWithGoogle } from "../authentication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

export default function AddComment({ user, gpt, handleAddComment }) {
  const [comment, setComment] = useState("");

  function handleSubmit() {
    if (comment.length > 0) {
      addComment(user, gpt, comment).then((res) => {
        const tmp = [...res];
        tmp[tmp.length - 1].submittedAt = "Just now";
        setComment("");
        handleAddComment(res);
      });
    }
  }

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="mb-10 mt-4">
        {user ? (
          <>
            <textarea
              className="w-full p-4 rounded-2xl focus:outline-none bg-transparent border  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
              placeholder="Add a comment..."
              rows={2}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              autoComplete="off"
            />

            <button
              className="cursor-pointer px-6 py-3 border border-transparent font-semibold rounded-full text-white bg-primary text-lg transform ease-in duration-100 mt-4"
              onClick={() => handleSubmit()}
            >
              Comment
            </button>
          </>
        ) : (
          <button
            className="cursor-pointer px-6 py-3 border border-transparent font-semibold rounded-full text-white bg-primary  text-lg transform ease-in duration-100"
            onClick={() => setIsOpen(!isOpen)}
          >
            Sign in to comment
          </button>
        )}
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-60"
            leave="ease-in duration-200"
            leaveFrom="opacity-60"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-darkGray border-2 border-white border-opacity-10 px-6 pb-16 pt-20 align-middle  transition-all text-center">
                  <button
                    className="fixed top-6 right-6 h-10 w-10 rounded-full bg-white text-white hover:text-red-500 bg-opacity-30 hover:bg-opacity-100"
                    onClick={() => closeModal()}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-xl mt-1.5 text-center"
                    />
                  </button>

                  <FontAwesomeIcon
                    icon={faLock}
                    className="text-5xl mb-4 text-white opacity-20"
                  />
                  <h1 className="text-3xl mb-4 font-semibold text-white">
                    Sign in to continue!
                  </h1>
                  <p className="mb-6 text-md text-gray">
                    Sign in to post, like and comment videos.
                  </p>

                  <div className="md:flex-row flex-col flex gap-3 justify-center">
                    <Link
                      to="/sign-up"
                      className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-primary"
                    >
                      Sign up
                    </Link>

                    <Link
                      to="/sign-in"
                      className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-transparent hover:bg-white hover:bg-opacity-10 border border-white border-opacity-10"
                    >
                      Sign in
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
