import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Contests() {
  return (
    <div className="bg-black mt-10">
      <div className="md:w-6/12 w-11/12 mx-auto h-full text-center">
        <div className="py-20">
          <div className="text-primary bg-primary bg-opacity-25 text-md px-2.5 py-1 rounded-full mt-2.5 w-36 mx-auto mb-6">
            Coming soon
          </div>
          <h1 className="text-4xl mb-3 text-white font-semibold">
            This feature is not available yet!
          </h1>
          <p className="text-gray text-xl">
            We're glad you landed here and that you are as excited about
            contests on Nolanz as we are. <br /> <br /> We are currently
            developing this feature and we would love your input on how we can
            tailor it for your needs. Don't hesitate to contact us at{" "}
            <span className="text-primary">hello@nolanz.ai</span> and join our
            Discord for more discussions.
            <br />
            <br />
            <a
              href="https://discord.gg/fNWzQSKYNE"
              target="_blank"
              className="text-center justify-center transition duration-150 cursor-pointer text-lg  rounded-full bg-primary text-white font-semibold px-8 py-3 w-full"
            >
              <FontAwesomeIcon
                icon={faDiscord}
                className="pt-2  mr-2 text-xl"
              />
              <span>Join Discord</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
