import React, { useEffect, useState } from "react";
import SelectFilmmaker from "../components/SelectFilmmaker";
import { getFilmmakersWithFilter, getMoreFilmmakers } from "../firestore";
import ProfileThumbnail from "../components/ProfileThumbnail";

export default function Filmmakers() {
  const [currentSort, setCurrentSort] = useState({
    name: "Most upvotes",
    value: "upvote_count",
    order: "desc",
  });

  function handleLoadMore() {
    setCurrentPage((old) => old + 1);
  }

  const [filmmakers, setFilmmakers] = useState();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getFilmmakersWithFilter(currentSort.value, currentSort.order, 8).then(
      (res) => setFilmmakers(res)
    );
  }, [currentSort]);

  useEffect(() => {
    if (currentPage) {
      getMoreFilmmakers(currentPage).then((res) =>
        setFilmmakers((old) => old.concat(res))
      );
    }
  }, [currentPage]);

  return (
    <>
      <div className="bg-black mt-10">
        <div className="w-11/12 mx-auto h-full">
          <div className="md:flex block gap-8 justify-between align-middle">
            <h1 className="text-white text-2xl font-semibold mt-4">
              ✨ Browse filmmakers
            </h1>

            <div className="flex gap-4 md:mt-0 mt-12">
              <div>
                <label class="block uppercase tracking-wider text-gray-700 text-xs font-bold mb-2 text-white">
                  Sort by
                </label>
                <SelectFilmmaker setCurrentSort={setCurrentSort} />
              </div>
            </div>
          </div>

          <div className="grid xl:grid-cols-4 lg:grid-cols-2 grid-cols-1 gap-8 mt-12 text-center">
            {filmmakers ? (
              filmmakers.map((filmmaker, i) => (
                <ProfileThumbnail filmmaker={filmmaker} key={i} />
              ))
            ) : (
              <p className="text-lg text-white text-center col-span-4 font-semibold mt-8">
                Loading...
              </p>
            )}
          </div>

          {filmmakers?.length % 8 === 0 ? (
            <div className="mt-16 w-full mx-auto text-center">
              <button
                onClick={() => handleLoadMore()}
                className="text-center justify-center transition duration-150 cursor-pointer text-lg  rounded-full  bg-white bg-opacity-20 text-white font-semibold px-6 py-3"
              >
                Load more
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}
