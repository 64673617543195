import React, { useState } from "react";
import { useEffect } from "react";
import { signInWithGoogle } from "../authentication";
import { Link, useNavigate, Redirect } from "react-router-dom";

import {
  getAuth,
  getRedirectResult,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithEmailAndPassword,
  getAdditionalUserInfo,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { submitFilmmaker } from "../firestore";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function Signin({ user }) {
  const navigate = useNavigate();
  const [onboarding, setOnboarding] = useState(false);
  const provider = new GoogleAuthProvider();
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [accountType, setAccountType] = useState("");
  const [somethingWrong, setSomethingWrong] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleSignInEmail() {
    console.log("Starting sign in with Email...");

    const auth = getAuth();
    signInWithEmailAndPassword(auth, inputEmail, inputPassword)
      .then((userCredential) => {
        const user = userCredential.user;
        navigate("/");
      })
      .catch((error) => {
        setSomethingWrong(true);
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }

  function handleSignUpGoogle() {
    console.log("Starting sign in with Google...");
    const auth = getAuth();
    signInWithPopup(auth, provider).then((result) => {
      setAccountType("google");
      const isNewUser = getAdditionalUserInfo(result).isNewUser;
      const uid = result.user.uid;
      const email = result.user.email;

      if (!isNewUser) {
        navigate("/");
      } else {
        setOnboarding(true);
        setUid(result.user.uid);
        setEmail(result.user.email);
      }
    });
  }

  const [uid, setUid] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [bio, setBio] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [instagram, setInstagram] = useState("");

  function checkValidEntry() {
    let validTwitter = true;
    let validInstagram = true;
    let validYoutube = true;

    if (
      twitter.length > 0 &&
      !twitter.includes("twitter.com") &&
      !twitter.includes("x.com")
    ) {
      validTwitter = false;
    } else {
      validTwitter = true;
    }

    if (instagram.length > 0 && !instagram.includes("instagram.com")) {
      validInstagram = false;
    }

    if (youtube.length > 0 && !youtube.includes("youtu")) {
      validYoutube = false;
    }

    if (
      uid.length > 0 &&
      email.length > 0 &&
      username.length > 0 &&
      validTwitter &&
      validInstagram &&
      validYoutube
    ) {
      return true;
    } else {
      return false;
    }
  }

  let validEmailSignUp = inputEmail.includes("@") && inputPassword.length > 5;

  function handleSubmit() {
    if (checkValidEntry()) {
      let obj = {
        uid: uid,
        username: username,
        email: email,
        bio: bio,
        twitter: twitter,
        youtube: youtube,
        instagram: instagram,
        followers: [],
        followers_count: 0,
        following: [],
        following_count: 0,
        videos: [],
        videos_count: 0,
        total_like_count: 0,
        joined: new Date(),
        accountType: accountType,
      };

      submitFilmmaker(obj).then((res) => {
        setSuccessfulSubmit(true);
      });
    }
  }

  return (
    <>
      <div className="bg-black min-h-screen">
        {!onboarding ? (
          <div className="md:w-96 w-11/12 mx-auto h-full mt-20 gap-3 pt-12 pb-6 px-6 bg-darkGray rounded-lg border border-white border-opacity-10">
            <h1 className="text-4xl text-center font-semibold mb-12 text-white">
              Sign in to Nolan
            </h1>

            <div className="flex flex-col gap-6">
              <div className="col-span-12 flex flex-col gap-3">
                <p className="block text-white text-md font-medium">Email</p>

                <input
                  className="p-3 rounded-lg focus:outline-none border bg-lightGray  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                  value={inputEmail}
                  type="email"
                  onChange={(e) => setInputEmail(e.target.value)}
                  autoComplete="off"
                />
              </div>

              <div className="col-span-12 flex flex-col gap-3">
                <p className="block text-white text-md font-medium">Password</p>

                <input
                  className="p-3 rounded-lg focus:outline-none border bg-lightGray  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                  type="password"
                  value={inputPassword}
                  onChange={(e) => setInputPassword(e.target.value)}
                  autoComplete="off"
                />
              </div>

              {validEmailSignUp ? (
                <button
                  onClick={() => handleSignInEmail()}
                  className="mt-3 text-center cursor-pointer text-lg  rounded-full  bg-primary text-white font-semibold px-8 py-3 w-full "
                >
                  Sign in
                </button>
              ) : (
                <button className="mt-3 text-center cursor-auto text-lg  rounded-full bg-white bg-opacity-10 text-white font-semibold px-8 py-3 w-full ">
                  Sign in
                </button>
              )}

              {somethingWrong ? (
                <p className="text-red-500 text-center text-md -mt-3">
                  Something went wrong. Please try again!
                </p>
              ) : null}
            </div>

            <div className="h-3 relative mt-8 mb-6">
              <div className="border border-white border-opacity-40" />
              <p className="text-white text-lg text-center mx-auto absolute -top-6  bg-darkGray  left-1/2 transform -translate-x-1/2 p-3 text-opacity-40">
                or
              </p>
            </div>

            <div className="flex gap-3 mx-auto justify-center w-full">
              <button
                onClick={() => handleSignUpGoogle()}
                className="flex gap-2 text-center justify-center transition duration-150 cursor-pointer text-lg  rounded-full  bg-white text-darkGray font-semibold px-8 py-3 w-full"
              >
                <img
                  className="w-6 h-6 mr-1"
                  src="https://www.svgrepo.com/show/475656/google-color.svg"
                  alt="google logo"
                />
                <span>Sign in with Google</span>
              </button>
            </div>
            <p className="text-gray text-xs text-center pt-4">
              By signing in to Nolan, you acknowledge that you agree to Nolan's{" "}
              <Link
                to="/terms-of-service"
                className="text-white font-semibold"
                target="_blank"
              >
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link
                to="/privacy-policy"
                className="text-white font-semibold"
                target="_blank"
              >
                Privacy Policy
              </Link>
              .
            </p>

            <p className="text-gray text-lg text-center mt-8 ">
              Don't have an account?{" "}
              <Link to="/sign-up" className="text-white underline">
                Sign up
              </Link>
            </p>
          </div>
        ) : !successfulSubmit ? (
          <div className="md:w-96 w-11/12 mx-auto h-full pt-20 gap-3 pb-20">
            <h1 className="text-4xl text-center font-semibold mb-4 text-white">
              Welcome!
            </h1>

            <p className="text-gray text-lg text-center mb-8">
              Let's get started with your profile.
            </p>

            <div className="bg-darkGray rounded-lg shadow-2xl py-6 px-6  border border-white border-opacity-10">
              <div className="flex flex-col gap-6">
                <div className="col-span-12 flex flex-col gap-3">
                  <p className="block text-white text-md font-medium">
                    Username (required)
                  </p>

                  <input
                    className="p-3 rounded-lg focus:outline-none border bg-lightGray  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoComplete="off"
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-white text-md font-medium flex justify-between">
                    <span>Bio (optional)</span>
                    <span className="text-gray text-sm font-semibold items-baseline">
                      ({bio.length} / 200)
                    </span>
                  </p>

                  <textarea
                    className="p-3 rounded-lg focus:outline-none bg-lightGray border  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                    placeholder="Description"
                    rows={3}
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    autoComplete="off"
                  />
                </div>

                <div className="col-span-12 flex flex-col gap-3">
                  <p className="block text-white text-md font-medium">
                    Social accounts (optional)
                  </p>

                  <div className="flex gap-3">
                    <div className="h-12 w-8 text-center">
                      <FontAwesomeIcon
                        icon={faTwitter}
                        className="text-2xl mt-3 text-gray"
                      />
                    </div>

                    <input
                      className="p-3 rounded-lg grow focus:outline-none border bg-lightGray  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                      placeholder="Link to your Twitter"
                      value={twitter}
                      onChange={(e) => setTwitter(e.target.value)}
                      autoComplete="off"
                    />
                  </div>

                  <div className="flex gap-3">
                    <div className="h-12 w-8 text-center">
                      <FontAwesomeIcon
                        icon={faYoutube}
                        className="text-2xl mt-3 text-gray"
                      />
                    </div>

                    <input
                      className="p-3 rounded-lg grow focus:outline-none border bg-lightGray  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                      placeholder="Link to your Youtube"
                      value={youtube}
                      onChange={(e) => setYoutube(e.target.value)}
                      autoComplete="off"
                    />
                  </div>

                  <div className="flex gap-3">
                    <div className="h-12 w-8 text-center">
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="text-2xl mt-3 text-gray"
                      />
                    </div>

                    <input
                      className="p-3 rounded-lg grow focus:outline-none border bg-lightGray  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                      placeholder="Link to your Instagram"
                      value={instagram}
                      onChange={(e) => setInstagram(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                </div>

                {checkValidEntry() ? (
                  <button
                    className="w-80 mx-auto mt-2 text-center px-5 py-2 border border-transparent font-semibold text-white  bg-primary text-lg rounded-full"
                    onClick={() => handleSubmit()}
                  >
                    Next
                  </button>
                ) : (
                  <button className="w-80 mx-auto cursor-not-allowed mt-2  text-center px-5 py-2 border border-transparent font-semibold text-white bg-white bg-opacity-10 text-lg rounded-full">
                    Next
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="md:w-96 w-11/12 mx-auto h-full pt-20 gap-3">
            <div className="bg-darkGray rounded-lg shadow-2xl py-6 px-6  border border-white border-opacity-10 text-center pb-1">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-7xl mb-4 text-green"
              />
              <h1 className="text-2xl font-semibold mb-3 text-white">
                Success! 🎉
              </h1>
              <p className="text-lg text-gray mb-8">
                Your profile was successfully created! Below you can find your
                URL.
              </p>

              <Link to={`/filmmakers/${uid}`}>
                <div className="hover:bg-white hover:bg-opacity-10 w-full text-left p-3 rounded-lg focus:outline-none bg-transparent border break-all border-white border-opacity-40 text-white text-md placeholder-white placeholder-opacity-40">
                  www.nolanz.ai/filmmakers/{uid}
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
