import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import {
  faCheckCircle,
  faChevronDown,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { getFilmmaker, submitGpt } from "../firestore";
import { tools } from "../utils/tools";
import { Link } from "react-router-dom";
import { getSfMostRecentMidnightTimestamp } from "../utils/times";
import { GENRE_TITLES } from "../utils/genres";

const genres = GENRE_TITLES;

export default function SubmitForm({ user }) {
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);
  const [docRef, setDocRef] = useState("");
  const [title, setTitle] = useState("");
  const [genre, setGenre] = useState(genres[0]);
  const [filmmaker, setFilmmaker] = useState();
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [validEntry, setValidEntry] = useState(false);
  const [selectedTools, setSelectedtools] = useState([]);
  const [allTools, setAllTools] = useState();
  const [query, setQuery] = useState("");

  useEffect(() => {
    getFilmmaker(user?.id).then((res) => {
      console.log(res);
      setFilmmaker(res);
    });
  }, [user]);

  useEffect(() => {
    setFilmmaker(user?.id);
  }, [user]);

  useEffect(() => {
    setAllTools(tools);
  }, [tools]);

  console.log("all tools: ", allTools);

  function handleTechnologyUpdate(input) {
    let list = [...selectedTools];
    const index = list.indexOf(input);
    if (index !== -1) {
      list.splice(index, 1);
    } else {
      list.push(input);
    }
    setSelectedtools(list);
  }

  useEffect(() => {
    setValidEntry(
      title.length > 0 &&
        genre !== "Select..." &&
        filmmaker[0].data.username.length > 0 &&
        url.length > 0 &&
        description.length < 1001 &&
        url.includes("youtu")
    );
  }, [title, genre, filmmaker, url, description]);

  function handleSubmit() {
    if (validEntry) {
      let obj = {
        title: title,
        genre: genre,
        filmmakerId: filmmaker[0].id,
        filmmakerUsername: filmmaker[0].data.username,
        url: url,
        description: description,
        tools: selectedTools,
        comments: [],
        upvotes: [],
        upvote_count: 0,
        submittedAt: new Date(),
        mostRecentMidnight: getSfMostRecentMidnightTimestamp(),
      };

      submitGpt(obj).then((res) => {
        setDocRef(res.id);
        setSuccessfulSubmit(true);

        let videos = [...filmmaker[0].data.videos];
        let videos_count = filmmaker[0].data.videos_count + 1;
        videos.push(res.id);

        updateDoc(doc(getFirestore(), "filmmakers", filmmaker[0].id), {
          videos: videos,
        }).then(() => {
          updateDoc(doc(getFirestore(), "filmmakers", filmmaker[0].id), {
            videos_count: videos_count,
          }).then(() => {
            console.log("Successfully submitted: ", res.id);
          });
        });
      });
    }
  }

  if (!user) {
    return (
      <div className="mx-auto mt-4 w-full max-w-md transform rounded-2xl bg-darkGray border-2 border-white border-opacity-10 px-6 pb-16 pt-20 align-middle  transition-all text-center">
        <FontAwesomeIcon
          icon={faLock}
          className="text-5xl mb-4 text-white opacity-20"
        />
        <h1 className="text-3xl mb-4 font-semibold text-white">
          Sign in to post a video!
        </h1>
        <p className="mb-6 text-md text-gray">
          Sign in to post, like and comment videos.
        </p>
        <div className="md:flex-row flex-col flex gap-3 justify-center">
          <Link
            to="/sign-up"
            className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-primary"
          >
            Sign up
          </Link>

          <Link
            to="/sign-in"
            className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-transparent hover:bg-white hover:bg-opacity-10 border border-white border-opacity-10"
          >
            Sign in
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <h1 className="text-4xl text-center font-semibold mb-6 text-white">
        Post video
      </h1>

      <div className="bg-darkGray rounded-lg shadow-2xl py-6 px-6  border border-white border-opacity-10">
        <div className="flex flex-col">
          {!successfulSubmit ? (
            <>
              <div className="flex flex-col gap-6">
                <div className="col-span-12 flex flex-col gap-3">
                  <p className="block text-white text-md font-medium">
                    Video title (required)
                  </p>

                  <input
                    className="p-3 rounded-lg focus:outline-none border bg-lightGray  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    autoComplete="off"
                  />
                </div>

                <div className="col-span-12 flex flex-col gap-3 mb-6">
                  <p className="block text-white text-md font-medium">
                    Genre (required)
                  </p>

                  <Listbox value={genre} onChange={setGenre}>
                    <div className="relative z-10">
                      <Listbox.Button className="border border-white border-opacity-40 relative w-full cursor-default text-left focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2  p-3 rounded-lg  bg-lightGray grow text-white">
                        <span
                          className={`block truncate ${
                            genre == "Select..." ? "text-white opacity-40" : ""
                          }`}
                        >
                          {genre}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-2 flex items-center pr-2">
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className="text-white text-opacity-40"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute mt-4 max-h-60 w-full overflow-auto rounded-md bg-lightGray py-3 shadow-lg ring-1 ring-black/5 focus:outline-none p-3 border border-white border-opacity-40 ">
                          {genres.map((c) => (
                            <Listbox.Option
                              key={c}
                              className={({ active }) =>
                                `relative select-none py-2 pl-2 pr-4 cursor-pointer text-white hover:bg-primary hover:text-black rounded-lg${
                                  active ? "" : ""
                                }`
                              }
                              value={c}
                            >
                              {({ category }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      category ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    {c}
                                  </span>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              </div>

              <div className="flex flex-col gap-2 mb-6">
                <p className="block text-white text-md font-medium">
                  Youtube link (required)
                </p>

                <input
                  className="p-3 rounded-lg focus:outline-none bg-lightGray border  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                  placeholder="Youtube URL"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div className="flex flex-col gap-2 mb-6">
                <p className="text-white text-md font-medium flex justify-between">
                  <span>Description (optional)</span>
                  <span className="text-gray text-sm font-semibold items-baseline">
                    ({description.length} / 1000)
                  </span>
                </p>

                <textarea
                  className="p-3 rounded-lg focus:outline-none bg-lightGray border  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                  placeholder="Description"
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div className="flex flex-col gap-2 mb-5">
                <p className="text-white text-md font-medium flex justify-between">
                  <span>Tools (optional)</span>
                  <span className="text-gray text-sm font-semibold items-baseline">
                    ({Object.values(selectedTools).filter(Boolean).length}{" "}
                    selected)
                  </span>
                </p>

                <div className="flex flex-wrap gap-x-2 gap-y-4 mt-2 bg-lightGray border border-white border-opacity-40 p-4 rounded-lg">
                  <input
                    className="py-3 px-5 w-full rounded-full focus:outline-none border bg-white bg-opacity-10  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white"
                    placeholder="Search tools..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    autoComplete="off"
                  />

                  <p className="text-gray mt-3 w-full border-b-2 border-white border-opacity-40 pb-3">
                    Showing{" "}
                    <span className="font-semibold text-white">
                      {
                        allTools?.filter((tool) => {
                          return tool.title
                            .toLowerCase()
                            .includes(query.toLowerCase());
                        }).length
                      }
                    </span>{" "}
                    results
                  </p>

                  <div className="max-h-48 overflow-y-scroll flex flex-wrap gap-x-2 gap-y-4 bg-lightGray">
                    {allTools
                      ?.filter((tool) => {
                        return tool.title
                          .toLowerCase()
                          .includes(query.toLowerCase());
                      })
                      .map((c) => {
                        return (
                          <span
                            className={`cursor-pointer text-sm font-medium me-2 px-3 py-2 rounded-lg ${
                              selectedTools.includes(c.id)
                                ? "bg-primary text-white border-primary border"
                                : "bg-lightGray text-white border border-white border-opacity-40 hover:bg-primary hover:border-primary"
                            }`}
                            onClick={() => handleTechnologyUpdate(c.id)}
                          >
                            {c.title}
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>

              {validEntry ? (
                <button
                  className="w-80 mx-auto mt-4 text-center mb-2 px-5 py-2 border border-transparent font-semibold text-white  bg-primary text-lg rounded-full"
                  onClick={() => handleSubmit()}
                >
                  Post video
                </button>
              ) : (
                <div className="w-80 mx-auto mt-4 text-center mb-2 px-5 py-2 border border-transparent font-semibold text-black  bg-white bg-opacity-30 text-lg rounded-full">
                  Post video
                </div>
              )}
            </>
          ) : (
            <div className="text-center mx-auto text-white py-8 px-4">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-7xl mb-4 text-green"
              />
              <h1 className="text-2xl font-semibold mb-3">
                Submission successful! 🎉
              </h1>
              <p className="text-md mb-1 text-gray">
                Thank you for posting your video to Nolan. Don't forget to share
                your creation to your friends!
              </p>

              <p className="text-white text-md font-medium flex justify-between mt-6 mb-2">
                Video URL
              </p>

              <Link to={`/videos/${docRef}`}>
                <div className="text-left p-3 rounded-lg focus:outline-none bg-transparent border break-all border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40">
                  www.nolanz.ai/videos/{docRef}
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
