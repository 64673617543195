import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  createUserWithEmailAndPassword,
} from "firebase/auth";

import { submitFilmmaker } from "../firestore";

export default function Signup() {
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [somethingWrong, setSomethingWrong] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleSignUpEmail() {
    console.log("Starting sign up with Email...");
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, inputEmail, inputPassword)
      .then((result) => {
        let obj = {
          uid: result.user.uid,
          username: result.user.uid,
          email: inputEmail,
          bio: "",
          twitter: "",
          youtube: "",
          instagram: "",
          followers: [],
          followers_count: 0,
          following: [],
          following_count: 0,
          videos: [],
          skills: [],
          videos_count: 0,
          selectedTools: [],
          total_like_count: 0,
          joined: new Date(),
          accountType: "email",
        };

        submitFilmmaker(obj).then((res) => {
          navigate("/settings");
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSignUpGoogle() {
    console.log("Starting sign up with Google...");
    const auth = getAuth();
    signInWithPopup(auth, provider).then((result) => {
      const isNewUser = getAdditionalUserInfo(result).isNewUser;

      if (!isNewUser) {
        navigate("/");
      } else {
        let obj = {
          uid: result.user.uid,
          username: result.user.uid,
          email: result.user.email,
          bio: "",
          twitter: "",
          youtube: "",
          instagram: "",
          followers: [],
          followers_count: 0,
          following: [],
          following_count: 0,
          videos: [],
          skills: [],
          videos_count: 0,
          selectedTools: [],
          total_like_count: 0,
          joined: new Date(),
          accountType: "google",
        };

        submitFilmmaker(obj).then((res) => {
          navigate("/settings");
        });
      }
    });
  }

  let validEmailSignUp = inputEmail.includes("@") && inputPassword.length > 5;

  return (
    <>
      <div className="bg-black min-h-screen">
        <div className="md:w-96 w-11/12 mx-auto h-full mt-20 gap-3 pt-12 pb-6 px-6 bg-darkGray rounded-lg border border-white border-opacity-10">
          <h1 className="text-4xl text-center font-semibold mb-12 text-white">
            Sign up to Nolan
          </h1>

          <div className="flex flex-col gap-6">
            <div className="col-span-12 flex flex-col gap-3">
              <p className="block text-white text-md font-medium">Email</p>

              <input
                className="p-3 rounded-lg focus:outline-none border bg-lightGray  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                value={inputEmail}
                type="email"
                onChange={(e) => setInputEmail(e.target.value)}
                autoComplete="off"
              />
            </div>

            <div className="col-span-12 flex flex-col gap-3">
              <p className="block text-white text-md font-medium">
                Password (must be at least 6 characters)
              </p>

              <input
                className="p-3 rounded-lg focus:outline-none border bg-lightGray  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                type="password"
                value={inputPassword}
                onChange={(e) => setInputPassword(e.target.value)}
                autoComplete="off"
              />
            </div>

            {validEmailSignUp ? (
              <button
                onClick={() => handleSignUpEmail()}
                className="mt-3 text-center cursor-pointer text-lg  rounded-full  bg-primary text-white font-semibold px-8 py-3 w-full "
              >
                Sign up
              </button>
            ) : (
              <button className="mt-3 text-center cursor-auto text-lg  rounded-full bg-white bg-opacity-10 text-white font-semibold px-8 py-3 w-full ">
                Sign up
              </button>
            )}

            {somethingWrong ? (
              <p className="text-red-500 text-center text-md -mt-3">
                Something went wrong. Please try again!
              </p>
            ) : null}
          </div>

          <div className="h-3 relative mt-8 mb-6">
            <div className="border border-white border-opacity-40" />
            <p className="text-white text-lg text-center mx-auto absolute -top-6  bg-darkGray  left-1/2 transform -translate-x-1/2 p-3 text-opacity-40">
              or
            </p>
          </div>

          <div className="flex gap-3 mx-auto justify-center w-full">
            <button
              onClick={() => handleSignUpGoogle()}
              className="flex gap-2 text-center justify-center transition duration-150 cursor-pointer text-lg  rounded-full  bg-white text-darkGray font-semibold px-8 py-3 w-full"
            >
              <img
                className="w-6 h-6 mr-1"
                src="https://www.svgrepo.com/show/475656/google-color.svg"
                alt="google logo"
              />
              <span>Sign up with Google</span>
            </button>
          </div>
          <p className="text-gray text-xs text-center pt-4">
            By signing up to Nolan, you acknowledge that you agree to Nolan's{" "}
            <Link
              to="/terms-of-service"
              className="text-white font-semibold"
              target="_blank"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              to="/privacy-policy"
              className="text-white font-semibold"
              target="_blank"
            >
              Privacy Policy
            </Link>
            .
          </p>

          <p className="text-gray text-lg text-center mt-8 ">
            Already have an account?{" "}
            <Link to="/sign-in" className="text-white underline">
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
