import { Navbar } from "../components/Navbar";
import React from "react";
import { useEffect } from "react";
import SubmitForm from "../components/SubmitForm";

export default function SubmitGPT({ user }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-black min-h-screen">
        <div className="lg:4/12 md:w-6/12 w-11/12 mx-auto h-full pt-20 gap-3 pb-20">
          <SubmitForm user={user} />
        </div>
      </div>
    </>
  );
}
