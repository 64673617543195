import { Link } from "react-router-dom";

export default function ToolCard({ tool }) {
  return (
    <Link
      to={`/tools/${tool?.url}`}
      className="bg-darkGray rounded-xl h-16 lg:gap-4 gap-2 items-center pl-2 pr-4 border-2 border-white border-opacity-10 text-left flex justify-start hover:border-primary"
    >
      {tool.logo ? (
        <img src={tool?.logo} className="w-10 h-10 rounded-full" />
      ) : (
        <div className="w-10 h-10 aspect-square bg-white bg-opacity-20 border-white border-opacity-10 rounded-full" />
      )}

      <h1 className="text-white font-semibold mb-0.5 truncate overflow-hidden whitespace-nowrap overflow-ellipsis">
        {tool?.title}
      </h1>
    </Link>
  );
}
