export default function TermsOfService() {
  return (
    <div className="md:w-8/12 w-11/12 mx-auto h-full pt-20">
      <h1 className="text-white text-4xl font-semibold">
        Terms of Service for nolanz.ai
      </h1>
      <h2 className="text-white font-semibold text-2xl mt-6">
        1. Introduction
      </h2>

      <p className="my-3 text-gray text-md">
        Welcome to nolanz.ai, a community-driven video sharing platform. By
        accessing or using our services, you agree to be bound by these Terms of
        Service ("Terms"). Please read them carefully.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">2. Eligibility</h2>
      <p className="my-3 text-gray text-md">
        You must be at least 13 years old to use nolanz.ai. By agreeing to these
        Terms, you represent and warrant that you are of legal age to form a
        binding contract with nolanz.ai.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        3. Account Registration
      </h2>
      <p className="my-3 text-gray text-md">
        To access certain features of the platform, you may be required to
        create an account. You are responsible for maintaining the
        confidentiality of your account credentials and for all activities that
        occur under your account.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        4. User Content and Data Sharing
      </h2>

      <h3 className="text-white font-semibold text-xl mt-4">
        4.1 User Content
      </h3>

      <p className="my-3 text-gray text-md">
        You retain all rights to any content you submit, post, or display on or
        through nolanz.ai ("User Content"). By submitting User Content, you
        grant nolanz.ai a worldwide, non-exclusive, royalty-free license to use,
        reproduce, modify, adapt, publish, and distribute your User Content in
        any and all media or distribution methods (now known or later
        developed).
      </p>

      <h3 className="text-white font-semibold text-xl mt-4">
        4.2 Submission of Video Content
      </h3>

      <p className="my-3 text-gray text-md">
        By submitting a video, you agree to share the following data with
        nolanz.ai: video title, genre, filmmaker name, email, YouTube link,
        description, and tools used. This data will be used to organize,
        categorize, and display content on nolanz.ai and for internal analytics
        and improvement of our services.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        5. Data Tracking and Analysis
      </h2>

      <h3 className="text-white font-semibold text-xl mt-4">
        5.1 Google Analytics
      </h3>

      <p className="my-3 text-gray text-md">
        By using nolanz.ai, you consent to the use of Google Analytics for
        tracking, analyzing, and reporting your interactions with the site.
        Google Analytics may collect data about your behavior on our platform,
        including pages visited, time spent on pages, and other navigational
        data. We use this information to improve our services and user
        experience.
      </p>

      <h3 className="text-white font-semibold text-xl mt-4">
        5.2 Account Creation Through Google
      </h3>

      <p className="my-3 text-gray text-md">
        When you create an account on nolanz.ai through Google, you understand
        and agree that Google will share your name, email address, language
        preference, and profile picture with nolanz.ai. This information is used
        to create your user profile and to provide a personalized experience on
        our platform.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        6. Consent and Privacy
      </h2>
      <p className="my-3 text-gray text-md">
        Your privacy is important to us. All data shared with nolanz.ai, whether
        through Google Analytics, account creation, or video submission, is
        handled in accordance with our Privacy Policy. By agreeing to these
        Terms, you acknowledge that you have read and understood our Privacy
        Policy.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        7. Content Restrictions
      </h2>

      <p className="my-3 text-gray text-md">
        You agree not to post User Content that:
      </p>
      <ul className="mt-2 text-gray text-md list-disc ml-4">
        <li>
          Is illegal, obscene, defamatory, threatening, infringing of
          intellectual property rights, invasive of privacy, or otherwise
          objectionable;
        </li>
        <li>
          Involves the transmission of "junk mail," "chain letters," or
          unsolicited mass mailing or "spamming";
        </li>
        <li>
          Contains software viruses or any other computer code designed to
          interrupt, destroy, or limit the functionality of any computer
          software or hardware.
        </li>
      </ul>

      <h2 className="text-white font-semibold text-2xl mt-6">
        8. nolanz.ai Rights
      </h2>

      <p className="my-3 text-gray text-md">
        nolanz.ai reserves the right to remove or modify User Content for any
        reason, including User Content that we believe violates these Terms or
        our policies.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        9. Copyright Infringement (DMCA Policy)
      </h2>

      <p className="my-3 text-gray text-md">
        If you believe that your copyright has been infringed upon by nolanz.ai,
        please notify us by providing a detailed description of the alleged
        infringement.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        10. Modifications to the Service
      </h2>

      <p className="my-3 text-gray text-md">
        nolanz.ai reserves the right at any time to modify or discontinue,
        temporarily or permanently, the Service (or any part thereof) with or
        without notice.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        11. Termination
      </h2>

      <p className="my-3 text-gray text-md">
        nolanz.ai may terminate or suspend your account and bar access to the
        Service immediately, without prior notice or liability, if you breach
        these Terms.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        12. Indemnification
      </h2>

      <p className="my-3 text-gray text-md">
        You agree to indemnify, defend, and hold harmless nolanz.ai, its
        officers, directors, employees, and agents from any and all claims,
        liabilities, damages, losses, and expenses arising from or in any way
        related to your use of the Service or your violation of these Terms.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        13. Limitation of Liability
      </h2>

      <p className="my-3 text-gray text-md">
        In no event will nolanz.ai be liable for any indirect, incidental,
        special, consequential or punitive damages arising out of or in
        connection with your use of the service.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        14. Governing Law
      </h2>

      <p className="my-3 text-gray text-md">
        These Terms shall be governed and construed in accordance with the laws
        of the State of New York, United States, without regard to its conflict
        of law provisions. We recognize that nolanz.ai operates globally, and
        you may be subject to different laws in your jurisdiction. Where those
        laws apply, they will not affect the enforceability of these Terms in
        the State of New York, United States.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        15. Changes to Terms
      </h2>
      <p className="my-3 text-gray text-md">
        nolanz.ai reserves the right, at our sole discretion, to modify or
        replace these Terms at any time. By continuing to access or use our
        Service after those revisions become effective, you agree to be bound by
        the revised terms.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">16. Contact Us</h2>

      <p className="my-3 text-gray text-md pb-20">
        If you have any questions about these Terms, please contact us at
        info@nolanz.ai.
      </p>
      {/* // **** */}
    </div>
  );
}
