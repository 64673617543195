import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SidebarItem from "./SidebarItem";
import {
  faHome,
  faPeopleGroup,
  faVideo,
  faUser,
  faPlus,
  faTimes,
  faCog,
  faRightFromBracket,
  faCompass,
  faHeart,
  faTools,
  faComment,
  faMoneyBill,
  faTrophy,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";

import { logOut } from "../authentication";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import { useEffect, useState, Fragment } from "react";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../images/logo_light.png";

export default function Sidebar({ user, handleShowMenu }) {
  const [activePage, setActivePage] = useState("");

  let location = useLocation();

  useEffect(() => {
    setActivePage(location.pathname.split("/"));
  }, [location]);

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const navigate = useNavigate();

  const handleLink = (url) => {
    navigate(url);
    setIsOpen(false);
    handleShowMenu(false);
  };

  return (
    <>
      <div className="h-full md:overflow-y-auto overflow-y-scroll pb-4 border-r border-white border-opacity-10 px-3 flex-col flex gap-6 relative grow ">
        <div className="flex w-full justify-between items-center px-3 pt-4">
          <div className="flex gap-5 items-center">
            <div onClick={() => handleLink("/")}>
              <Link
                className="text-white font-semibold tracking-wider flex items-center gap-3"
                to="/"
              >
                <img src={logo} className="w-32" />
              </Link>
            </div>

            <div className="text-primary bg-primary bg-opacity-25 text-xs px-2.5 py-1 rounded-lg mt-2.5">
              Beta
            </div>
          </div>

          <button
            className="w-12 h-12 block lg:hidden bg-primary text-white rounded-lg"
            onClick={() => handleShowMenu(false)}
          >
            <FontAwesomeIcon icon={faTimes} className="text-xl mt-1" />
          </button>
        </div>

        <div className="border-b-2 border-white border-opacity-10 -mt-2">
          <div onClick={() => handleLink("/")}>
            <SidebarItem
              title="Home"
              icon={faHome}
              active={activePage[1] == ""}
            />
          </div>

          {/* <div onClick={() => handleLink("/following")}>
            <SidebarItem
              title="Trending"
              icon={faFire}
              active={activePage[1] == "following"}
            />
          </div> */}

          <div onClick={() => handleLink("/following")}>
            <SidebarItem
              title="Following"
              icon={faHeart}
              active={activePage[1] == "following"}
            />
          </div>

          <SidebarItem
            title="Discord"
            to="https://discord.gg/fNWzQSKYNE"
            icon={faDiscord}
            external={true}
            active={activePage[1] == "discord"}
          />
        </div>

        <div className="border-b-2 border-white border-opacity-10">
          <h1 className="text-lg text-white font-semibold px-3 -mt-3">
            Browse
          </h1>
          <div onClick={() => handleLink("/videos")}>
            <SidebarItem
              title="Videos"
              icon={faVideo}
              active={activePage[1] == "videos"}
            />
          </div>

          <div onClick={() => handleLink("/filmmakers")}>
            <SidebarItem
              title="Filmmakers"
              icon={faPeopleGroup}
              active={activePage[1] == "filmmakers" && activePage[2] == null}
            />
          </div>

          <div onClick={() => handleLink("/tools")}>
            <SidebarItem
              title="Tools"
              icon={faTools}
              active={activePage[1] == "tools" && activePage[2] == null}
            />
          </div>

          <div onClick={() => handleLink("/contests")}>
            <SidebarItem
              title="Contests"
              icon={faTrophy}
              active={activePage[1] == "contests"}
            />
          </div>

          <div onClick={() => handleLink("/jobs")}>
            <SidebarItem
              title="Jobs"
              icon={faBriefcase}
              active={activePage[1] == "jobs"}
            />
          </div>

          {/* <SidebarItem
            title="Feedback"
            to="https://app.loopedin.io/nolanz/ideas"
            icon={faComment}
            external={true}
            active={activePage[1] == "roadmap"}
          /> */}
        </div>

        {!user ? (
          <div className="bg-darkGray border border-white border-opacity-10 p-4 rounded-lg -mt-3">
            <p className="text-gray mb-4">
              Sign in to post, like and comment videos.
            </p>

            <div className="md:flex-row flex-col flex gap-3">
              <div
                onClick={() => handleLink("/sign-up")}
                className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-5 py-2 bg-primary"
              >
                Sign up
              </div>

              <div
                onClick={() => handleLink("/sign-in")}
                className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-5 py-2 bg-transparent hover:bg-white hover:bg-opacity-10 border border-white border-opacity-10"
              >
                Sign in
              </div>
            </div>
          </div>
        ) : (
          <div className="">
            <h1 className="text-lg text-white font-semibold px-3 -mt-3">
              For you
            </h1>

            <div onClick={() => handleLink("/post-video")}>
              <SidebarItem
                title="Post video"
                icon={faPlus}
                active={activePage[1] == "post-video"}
              />
            </div>
            <div onClick={() => handleLink(`/filmmakers/${user?.id}`)}>
              <SidebarItem
                title="Profile"
                icon={faUser}
                active={
                  activePage[1] == "filmmakers" && activePage[2] == user.id
                }
              />
            </div>

            <div onClick={() => handleLink("/settings")}>
              <SidebarItem
                title="Settings"
                icon={faCog}
                active={activePage[1] == "settings"}
              />
            </div>
            <div onClick={() => logOut()}>
              <SidebarItem title="Log out" icon={faRightFromBracket} />
            </div>
          </div>
        )}
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-60"
            leave="ease-in duration-200"
            leaveFrom="opacity-60"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-darkGray border-2 border-white border-opacity-10 px-6 pb-16 pt-20 align-middle  transition-all text-center">
                  <button
                    className="fixed top-6 right-6 h-10 w-10 rounded-full bg-white text-white hover:text-red-500 bg-opacity-30 hover:bg-opacity-100"
                    onClick={() => closeModal()}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-xl mt-1.5 text-center"
                    />
                  </button>

                  <FontAwesomeIcon
                    icon={faLock}
                    className="text-5xl mb-4 text-white opacity-20"
                  />
                  <h1 className="text-3xl mb-4 font-semibold text-white">
                    Sign in to continue!
                  </h1>
                  <p className="mb-6 text-md text-gray">
                    Sign in to post, like and comment videos.
                  </p>

                  <div className="md:flex-row flex-col flex gap-3 justify-center">
                    <button
                      onClick={() => handleLink("/sign-up")}
                      className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-primary"
                    >
                      Sign up
                    </button>

                    <button
                      to="/sign-in"
                      onClick={() => handleLink("/sign-in")}
                      className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-transparent hover:bg-white hover:bg-opacity-10 border border-white border-opacity-10"
                    >
                      Sign in
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
