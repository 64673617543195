import {
  collection,
  getDocs,
  documentId,
  limit,
  orderBy,
  query,
  where,
  addDoc,
  setDoc,
  startAfter,
  doc,
  runTransaction,
} from "firebase/firestore";
import { db } from "./firebase";
import { getSfMostRecentMidnightTimestamp } from "./utils/times";

let latestFilter = {};
let latestDoc = {};
let latestSubscriptionDoc = {};
let latestDocHottest = null;
let latestFollowers = {};
let latestFollowing = {};
let latestDocRecent = {};
let latestVideoFromFilmmaker = {};
let latestVideosWithTool = {};

const gptsRef = collection(db, "videos");
const filmmakersRef = collection(db, "filmmakers");

export async function getGpt(id) {
  const q = query(gptsRef, where(documentId(), "==", `${id}`));
  const querySnapshot = await getDocs(q);
  return querySnapshot.empty
    ? null
    : querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
}

export async function getFilmmaker(id) {
  const q = query(filmmakersRef, where(documentId(), "==", `${id}`));
  const querySnapshot = await getDocs(q);
  return querySnapshot.empty
    ? null
    : querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
}

export async function getFilmmakersWithMostVideos(lim) {
  const q = query(filmmakersRef, orderBy("videos_count", "desc"), limit(lim));

  const querySnapshot = await getDocs(q);

  return querySnapshot.empty
    ? null
    : querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
}

export async function getHottest(lim, getMore = false) {
  const mostRecentMidnightTimestamp = getSfMostRecentMidnightTimestamp();
  let q = "";
  if (getMore) {
    q = query(
      gptsRef,
      where("mostRecentMidnight", "==", mostRecentMidnightTimestamp),
      orderBy("upvote_count", "desc"),
      startAfter(latestDocHottest),
      limit(lim)
    );
  } else {
    q = query(
      gptsRef,
      where("mostRecentMidnight", "==", mostRecentMidnightTimestamp),
      orderBy("upvote_count", "desc"),
      limit(lim)
    );
  }

  const querySnapshot = await getDocs(q);

  if (querySnapshot.docs.length > 0) {
    latestDocHottest = querySnapshot.docs[querySnapshot.docs.length - 1];
  }

  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    data: doc.data(),
  }));
}

export async function getMostRecent(lim, getMore = false) {
  let q = "";
  if (getMore) {
    q = query(
      gptsRef,
      orderBy("submittedAt", "desc"),
      startAfter(latestDocRecent),
      limit(lim)
    );
  } else {
    q = query(gptsRef, orderBy("submittedAt", "desc"), limit(lim));
  }
  const querySnapshot = await getDocs(q);

  latestDocRecent = querySnapshot.docs[querySnapshot.docs.length - 1];

  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    data: doc.data(),
  }));
}

export async function getGptsWithFilter(
  where_property,
  where_operator,
  where_value,
  order_value,
  order_order = "desc",
  lim = 4
) {
  latestFilter = {
    where_property: where_property,
    where_operator: where_operator,
    where_value: where_value,
    order_value: order_value,
    order_order: order_order,
    lim: lim,
  };
  if (
    where_property === null ||
    where_operator === null ||
    where_value === null
  ) {
    const q = query(
      gptsRef,
      orderBy(order_value || where_property, order_order),
      limit(lim)
    );
    const querySnapshot = await getDocs(q);
    latestDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

    return querySnapshot.empty
      ? null
      : querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
  }
  const q = query(
    gptsRef,
    where(where_property, where_operator, where_value),
    orderBy(order_value || where_property, order_order),
    limit(lim)
  );
  const querySnapshot = await getDocs(q);

  latestDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

  return querySnapshot.empty
    ? null
    : querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
}

export async function getMoreGpts(i, filter = latestFilter) {
  if (
    latestFilter.where_property === null ||
    latestFilter.where_operator === null ||
    latestFilter.where_value === null
  ) {
    const q = query(
      gptsRef,
      orderBy(
        latestFilter.order_value || latestFilter.where_property,
        latestFilter.order_order
      ),
      startAfter(latestDoc),
      limit(latestFilter.lim)
    );
    const querySnapshot = await getDocs(q);
    latestDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

    return querySnapshot.empty
      ? null
      : querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
  }
  const q = query(
    gptsRef,
    where(
      latestFilter.where_property,
      latestFilter.where_operator,
      latestFilter.where_value
    ),
    orderBy(
      latestFilter.order_value || latestFilter.where_property,
      latestFilter.order_order
    ),
    startAfter(latestDoc),
    limit(latestFilter.lim)
  );
  const querySnapshot = await getDocs(q);
  latestDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    data: doc.data(),
  }));
}

export async function submitGpt(gpt) {
  const docRef = await addDoc(collection(db, "videos"), gpt);
  // try {
  //   await runTransaction(db, async (transaction) => {
  //     const sfDoc = await transaction.get(docRef);
  //     if (!sfDoc.exists()) {
  //       throw "Document does not exist!";
  //     }
  //     transaction.set(docRef);
  //   });
  // } catch (e) {
  //   console.log("Submit failed: ", e);
  // }
  return docRef;
}

export async function isUsernameTaken(username) {
  const q = query(filmmakersRef, where("username", "==", username));
  let qs = await getDocs(q);
  return !qs.empty;
}

export async function submitFilmmaker(filmmaker) {
  const docRef = await setDoc(doc(db, "filmmakers", filmmaker.uid), {
    username: filmmaker.username,
    email: filmmaker.email,
    bio: filmmaker.bio,
    twitter: filmmaker.twitter,
    youtube: filmmaker.youtube,
    instagram: filmmaker.instagram,
    followers: filmmaker.followers,
    following: filmmaker.following,
    skills: {
      "Character designer": [],
      "Characters consistency": [],
      Storytelling: [],
      Screenwriting: [],
      "Art direction": [],
      Narrative: [],
      Creativity: [],
      Cinematography: [],
      "Sound design": [],
      "Prompt master": [],
    },
    videos: filmmaker.videos,
    total_like_count: filmmaker.total_like_count,
    joined: filmmaker.joined,
    followers_count: filmmaker.followers_count,
    following_count: filmmaker.following_count,
    videos_count: filmmaker.videos_count,
    accountType: filmmaker.accountType,
    reviews: [],
    followers: [],
    following: [],
    selectedTools: [],
  });
  try {
    await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(docRef);
      if (!sfDoc.exists()) {
        throw "Document does not exist!";
      }
      transaction.set(docRef);
    });
  } catch (e) {
    console.log("Submit failed: ", e);
  }
  return docRef;
}

export async function toggleUpvoteGpt(gpt, uid, hasUserUpvoted) {
  const docRef = await doc(db, "videos", gpt.id);

  try {
    const newUpvotes = await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(docRef);
      if (!sfDoc.exists()) {
        throw "Document does not exist!";
      }

      let oldUpvotes = sfDoc.data().upvotes;
      let newUpvotes = [];

      if (hasUserUpvoted) {
        const remove_index = oldUpvotes.findIndex((obj) => obj.uid === uid);
        newUpvotes = oldUpvotes.filter(
          (element, index) => index !== remove_index
        );
      } else {
        newUpvotes = [
          ...oldUpvotes,
          {
            submittedAt: new Date(),
            uid: uid,
          },
        ];
      }
      transaction.update(docRef, {
        upvote_count: newUpvotes.length,
        upvotes: newUpvotes,
      });
      return newUpvotes;
    });
    return newUpvotes;
  } catch (e) {
    console.log("Upvote failed: ", e);
  }
}

export async function toggleEndorsement(
  skill,
  filmmaker,
  endorser,
  userHasEndorsed
) {
  try {
    let docRef = doc(db, "filmmakers", filmmaker);
    const finalResult = await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(docRef);

      if (!sfDoc.exists()) {
        throw "Document does not exist!";
      }

      let oldSkills = sfDoc.data().skills;
      let oldEndorsers = oldSkills[skill];
      let newSkills = [];
      let newEndorsers = [];

      if (userHasEndorsed) {
        newEndorsers = oldEndorsers.filter((a) => a !== endorser);
        newSkills = { ...oldSkills, [skill]: newEndorsers };
      } else {
        let tmp = [...oldEndorsers];
        tmp.push(endorser);
        newSkills = { ...oldSkills, [skill]: tmp };
      }

      transaction.update(docRef, {
        skills: newSkills,
      });

      return newSkills;
    });
    return finalResult;
  } catch (e) {
    console.log("Endorsement failed: ", e);
  }

  // const docRef = await doc(db, "filmmakers", filmmaker);

  // try {
  //   runTransaction(db, async (transaction) => {
  //     const sfDoc = await transaction.get(docRef);

  //     if (!sfDoc.exists()) {
  //       throw "Document does not exist!";
  //     }

  //     transaction.update(docRef, {
  //       skills: skills,
  //     });
  //   }).then((res) => {
  //     console.log("Upvote successful");
  //     return res;
  //   });
  // } catch (e) {}
}

export function userHasUpvoted(upvotes, uid) {
  let hasUpvoted = false;

  console.log("upvotes", upvotes);

  upvotes.forEach((vote) => {
    if (vote.uid == uid) {
      hasUpvoted = true;
    }
  });

  return hasUpvoted;
}

export async function addComment(user, gpt, comment) {
  const filmmaker = await getFilmmaker(user.id);

  console.log(user);
  console.log(filmmaker);

  const sfTime = new Date(
    new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    })
  );

  const docRef = doc(db, "videos", gpt.id);

  try {
    const newComments = await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(docRef);
      if (!sfDoc.exists()) {
        throw "Document does not exist!";
      }

      const oldComments = sfDoc.data().comments;
      const newComments = [
        ...oldComments,
        {
          submittedAt: new Date(),
          username: filmmaker[0].data.username,
          userId: filmmaker[0].id,
          text: comment,
        },
      ];
      transaction.update(docRef, { comments: newComments });
      return newComments;
    });
    return newComments;
  } catch (e) {
    console.log("Comment failed: ", e);
  }
}

export async function addReview(from, to, comment) {
  const sender = await getFilmmaker(from.id);

  const sfTime = new Date(
    new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    })
  );

  const docRef = doc(db, "filmmakers", to.id);

  try {
    const newReviews = await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(docRef);
      if (!sfDoc.exists()) {
        throw "Document does not exist!";
      }

      const oldReviews = sfDoc.data().reviews;
      const newReviews = [
        ...oldReviews,
        {
          submittedAt: new Date(),
          username: sender[0].data.username,
          userId: sender[0].id,
          text: comment,
        },
      ];
      transaction.update(docRef, { reviews: newReviews });
      return newReviews;
    });
    return newReviews;
  } catch (e) {
    console.log("Comment failed: ", e);
  }
}

export async function getFilmmakersWithFilter(
  order_value,
  order_order = "desc",
  lim = 4
) {
  latestFilter = {
    order_value: order_value,
    order_order: order_order,
    lim: lim,
  };

  const q = query(filmmakersRef, orderBy(order_value, order_order), limit(lim));
  const querySnapshot = await getDocs(q);
  latestDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

  return querySnapshot.empty
    ? null
    : querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
}

export async function getMoreFilmmakers(i, filter = latestFilter) {
  const q = query(
    filmmakersRef,
    orderBy(latestFilter.order_value, latestFilter.order_order),
    startAfter(latestDoc),
    limit(latestFilter.lim)
  );
  const querySnapshot = await getDocs(q);
  latestDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

  return querySnapshot.empty
    ? null
    : querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
}

export async function getSubscriptions(max, following) {
  const fetchedVideos = [];

  try {
    const q = query(
      gptsRef,
      where("filmmakerId", "in", following),
      orderBy("submittedAt", "desc"),
      limit(max)
    );

    const querySnapshot = await getDocs(q);
    latestSubscriptionDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching videos:", error);
  }
}

export async function getMoreSubscriptions(max, following) {
  const q = query(
    gptsRef,
    where("filmmakerId", "in", following),
    orderBy("submittedAt", "desc"),
    startAfter(latestSubscriptionDoc),
    limit(max)
  );
  const querySnapshot = await getDocs(q);
  latestSubscriptionDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

  return querySnapshot.empty
    ? null
    : querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
}

export async function toggleFollow(filmmaker, follower, userHasFollowed) {
  try {
    let filmmakerRef = doc(db, "filmmakers", filmmaker);
    let followerRef = doc(db, "filmmakers", follower);
    const finalResult = await runTransaction(db, async (transaction) => {
      const filmmakerDoc = await transaction.get(filmmakerRef);
      const followerDoc = await transaction.get(followerRef);

      if (!filmmakerDoc.exists()) {
        throw "Document does not exist!";
      }

      if (!followerDoc.exists()) {
        throw "Document does not exist!";
      }

      let oldFollowers = filmmakerDoc.data().followers;
      let newFollowers = [];

      let oldFollowing = followerDoc.data().following;
      let newFollowing = [];

      if (userHasFollowed) {
        newFollowers = oldFollowers.filter((a) => a !== follower);
        oldFollowing = oldFollowing.filter((a) => a !== filmmaker);
        // to-do, also make request to follower doc to remove filmmaker from field "following"
      } else {
        let tmp1 = [...oldFollowers];
        tmp1.push(follower);
        newFollowers = tmp1;

        let tmp2 = [...oldFollowing];
        tmp2.push(filmmaker);
        newFollowing = tmp2;
      }

      transaction.update(filmmakerRef, {
        followers: newFollowers,
      });

      transaction.update(followerRef, {
        following: newFollowing,
      });

      return newFollowers;
    });
    return finalResult;
  } catch (e) {
    console.log("Following failed failed: ", e);
  }
}

export async function getVideosFromFilmmaker(id, lim) {
  const fetchedVideos = [];

  try {
    const q = query(
      gptsRef,
      where("filmmakerId", "==", id),
      orderBy("submittedAt", "desc"),
      limit(lim)
    );

    const querySnapshot = await getDocs(q);
    latestVideoFromFilmmaker =
      querySnapshot.docs[querySnapshot.docs.length - 1];
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching videos:", error);
  }
}

export async function getMoreVideosFromFilmmaker(id, lim) {
  const q = query(
    gptsRef,
    where("filmmakerId", "==", id),
    orderBy("submittedAt", "desc"),
    startAfter(latestVideoFromFilmmaker),
    limit(lim)
  );
  const querySnapshot = await getDocs(q);
  latestVideoFromFilmmaker = querySnapshot.docs[querySnapshot.docs.length - 1];

  return querySnapshot.empty
    ? null
    : querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
}

export async function getVideosWithTool(id, lim) {
  try {
    const q = query(
      gptsRef,
      where("tools", "array-contains", id),
      orderBy("submittedAt", "desc"),
      limit(lim)
    );

    const querySnapshot = await getDocs(q);
    latestVideosWithTool = querySnapshot.docs[querySnapshot.docs.length - 1];
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching videos:", error);
  }
}

export async function getMoreVideosWithTool(id, lim) {
  const q = query(
    gptsRef,
    where("tools", "array-contains", id),
    orderBy("submittedAt", "desc"),
    startAfter(latestVideosWithTool),
    limit(lim)
  );
  const querySnapshot = await getDocs(q);
  latestVideosWithTool = querySnapshot.docs[querySnapshot.docs.length - 1];

  return querySnapshot.empty
    ? null
    : querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
}

export async function getFollowers(followers, lim) {
  if (followers.length == 0) {
    return;
  } else {
    const q = query(
      filmmakersRef,
      where(documentId(), "in", followers),
      limit(lim)
    );

    const querySnapshot = await getDocs(q);

    return querySnapshot.empty
      ? null
      : querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
  }
}

export async function getFollowing(following, lim) {
  if (following.length == 0) {
    return;
  } else {
    const q = query(
      filmmakersRef,
      where(documentId(), "in", following),
      limit(lim)
    );

    const querySnapshot = await getDocs(q);

    return querySnapshot.empty
      ? null
      : querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
  }
}
