export default function PrivacyPolicy() {
  return (
    <div className="md:w-8/12 w-11/12 mx-auto h-full pt-20">
      <h1 className="text-white text-4xl font-semibold">
        Privacy Policy for nolanz.ai
      </h1>

      <h2 className="text-white font-semibold text-2xl mt-6">
        1. Introduction
      </h2>
      <p className="my-3 text-gray text-md">
        nolanz.ai ("we", "us", or "our") respects the privacy of our users
        ("user" or "you"). This Privacy Policy explains how we collect, use,
        disclose, and safeguard your information when you visit our website and
        use our services. We encourage you to read this Privacy Policy carefully
        when using our services or transacting business with us.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        2. Information We Collect
      </h2>

      <h3 className="text-white font-semibold text-xl mt-4">
        2.1 Personal Information
      </h3>

      <p className="my-3 text-gray text-md">
        You provide us with personal information when creating an account, such
        as your name, email address, language preference, and profile picture.
        This information is shared with us by Google when you sign up using your
        Google account.
      </p>

      <h3 className="text-white font-semibold text-xl mt-4">
        2.2 Submission Information
      </h3>
      <p className="my-3 text-gray text-md">
        When you submit a video, you agree to share specific information with
        us, including the video title, genre, filmmaker name, email, YouTube
        link, description, and tools used.
      </p>

      <h3 className="text-white font-semibold text-xl mt-4">
        2.3 Automated Information Collection
      </h3>
      <p className="my-3 text-gray text-md">
        When you access our service, we may automatically collect certain
        information about your device and usage, including your IP address,
        browser type, and information about how you interact with our website,
        using technologies such as cookies and Google Analytics.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        3. Use of Your Information
      </h2>
      <p className="my-3 text-gray text-md">
        We use the information we collect about you to:
      </p>

      <ul className="mt-2 text-gray text-md list-disc ml-4">
        <li>Provide, maintain, and improve our services;</li>
        <li>Personalize your user experience;</li>
        <li>Communicate with you about your account and our services;</li>
        <li>Perform analytics and conduct customer research;</li>
        <li>
          Comply with our legal obligations, resolve disputes, and enforce our
          agreements.
        </li>
      </ul>

      <h2 className="text-white font-semibold text-2xl mt-6">
        4. Sharing Your Information
      </h2>

      <p className="my-3 text-gray text-md">
        We may share information we have collected about you in certain
        situations. Your information may be disclosed as follows:
      </p>

      <ul className="mt-2 text-gray text-md list-disc ml-4">
        <li>
          By Law or to Protect Rights: If we believe the release of information
          about you is necessary to respond to legal process, to investigate or
          remedy potential violations of our policies, or to protect the rights,
          property, and safety of others, we may share your information as
          permitted or required by any applicable law, rule, or regulation.
        </li>
        <li>
          Third-Party Service Providers: We may share your information with
          third parties that perform services for us or on our behalf, including
          payment processing, data analysis, email delivery, hosting services,
          customer service, and marketing assistance.
        </li>
      </ul>

      <p className="my-3 text-gray text-md"> </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        5. Your Data Protection Rights
      </h2>

      <p className="my-3 text-gray text-md">
        Depending on your jurisdiction, you may have rights under data
        protection laws in relation to your personal data, such as the right to
        access, correct, or delete the personal information we hold about you.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        6. Cookies and Similar Technologies
      </h2>

      <p className="my-3 text-gray text-md">
        We use cookies and similar tracking technologies to track the activity
        on our service and hold certain information. You can instruct your
        browser to refuse all cookies or to indicate when a cookie is being
        sent.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        7. Security of Your Information
      </h2>

      <p className="my-3 text-gray text-md">
        We use administrative, technical, and physical security measures to help
        protect your personal information. While we have taken reasonable steps
        to secure the personal information you provide to us, please be aware
        that despite our efforts, no security measures are perfect or
        impenetrable.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        8. Policy for Children
      </h2>

      <p className="my-3 text-gray text-md">
        We do not knowingly collect personally identifiable information from
        children under 13. If we become aware that we have collected personal
        information from children under age 13, we will take steps to remove
        that information from our servers.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">
        9. Changes to This Privacy Policy
      </h2>
      <p className="my-3 text-gray text-md">
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>

      <h2 className="text-white font-semibold text-2xl mt-6">10. Contact us</h2>
      <p className="my-3 text-gray text-md pb-20">
        If you have questions or comments about this Privacy Policy, please
        contact us at info@nolanz.ai.
      </p>
    </div>
  );
}
