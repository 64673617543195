import React from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "../firebase";
import logo from "../images/logo_light.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCircle,
  faDotCircle,
  faHamburger,
  faHandsClapping,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

export function Navbar({ handleShowMenu }) {
  const { user } = useAuthState();

  return (
    <div className="lg:hidden bg-black w-full border-b border-white border-opacity-10 z-50 px-6 py-4 justify-between flex items-center sticky top-0">
      <div className="flex gap-5 items-center">
        <Link
          className="text-white font-semibold tracking-wider flex items-center gap-3"
          to="/"
        >
          <img src={logo} className="w-32" />
        </Link>

        <div className="text-primary bg-primary bg-opacity-25 text-xs px-2.5 py-1 rounded-lg mt-2.5">
          Beta
        </div>
      </div>

      <button
        className="w-12 h-12 block lg:hidden bg-primary text-white rounded-lg"
        onClick={() => handleShowMenu(true)}
      >
        <FontAwesomeIcon icon={faBars} className="text-xl mt-1" />
      </button>
    </div>
  );
}
