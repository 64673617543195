import { getStorage, getDownloadURL, ref } from "@firebase/storage";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

export default function ProfilePicture({ user, size, center }) {
  const [picture, setPicture] = useState();
  const storage = getStorage();

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          const url = await getDownloadURL(
            ref(storage, `/profile_pictures/${user}`)
          );
          setPicture(url);
        } catch (error) {
          setPicture(null);
        }
      }
    };

    fetchData();
  }, [user]);

  if (!picture) {
    return (
      <div
        className={`rounded-full text-center bg-transparent border-2 border-gray border-opacity-40 ${
          size == "small" ? "w-8 h-8" : "w-28 h-28"
        } ${center ? "mx-auto" : ""}`}
      >
        <FontAwesomeIcon
          icon={faUser}
          className={`text-gray ${
            size == "small" ? "text-xs pt-2" : "text-4xl pt-8 "
          }`}
        />
      </div>
    );
  } else {
    return (
      <img
        alt="logo"
        className={`rounded-full border-white border-opacity-10 cover ${
          size == "small" ? "w-8 h-8" : "w-28 h-28"
        } ${center ? "mx-auto" : ""}`}
        src={picture}
      />
    );
  }
}
