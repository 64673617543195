export default function Video({ url }) {
  function youtubeUrlToId(url) {
    const patterns = [
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?.*?v=([a-zA-Z0-9_-]{11})/,
      /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})/,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/.*?\?v=([a-zA-Z0-9_-]{11})/,
    ];

    for (let i = 0; i < patterns.length; i++) {
      const match = url.match(patterns[i]);
      if (match && match[1]) {
        return match[1];
      }
    }

    return null;
  }

  return (
    <div className="aspect-video ">
      <iframe
        className="min-h-96 h-full aspect-auto w-full rounded-lg"
        src={`https://www.youtube.com/embed/${youtubeUrlToId(url)}`}
        width="100%"
        title="YouTube video player"
        frameborder="0"
        autoPlay
        allowFullScreen
      ></iframe>
    </div>
  );
}
