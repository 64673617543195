export default function ApplyToListTool() {
  return (
    <div className="bg-black mt-10">
      <div className="md:w-6/12 w-11/12 mx-auto h-full text-center">
        <div className="py-20">
          <h1 className="text-4xl mb-3 text-white font-semibold">
            Want to list your tool? Contact us!
          </h1>
          <p className="text-gray text-xl">
            If you are developing a tool that makes life easier for AI
            filmmakers, contact us at{" "}
            <span className="text-primary">hello@nolanz.ai</span>.
          </p>
        </div>
      </div>
    </div>
  );
}
