import React, { useState } from "react";
import { Jumbotron } from "../components/Jumbotron";
import Directory from "../components/Directory";
import Banner from "../components/Banner";

export default function Videos({ user }) {
  return (
    <div className="my-10">
      <Directory />
    </div>
  );
}
