export const tools = [
  {
    title: "Open AI Sora",
    category: ["video"],
    showOnHome: true,
    url: "open-ai-sora",
    logo: "https://play-lh.googleusercontent.com/8XCwpfWc9YkehwhrhoID6PGhs5SaSJoocS0oTBA8EsGFGLrj32oIYu5UKsIO7wdU1PQZ",
    website: "https://openai.com/sora",
    description:
      "Sora is an AI model that can create realistic and imaginative scenes from text instructions. Sora can generate videos up to a minute long while maintaining visual quality and adherence to the user’s prompt.",
    id: "1",
  },
  {
    title: "RunwayML",
    showOnHome: true,
    category: ["video"],
    url: "runway-ml",
    logo: "https://pbs.twimg.com/profile_images/1542885861330673664/ukf6-PFA_400x400.jpg",
    website: "https://runwayml.com",
    description:
      "Runway is a multimodal AI system that can generate novel videos with text, images or video clips. Realistically and consistently synthesize new videos. Either by applying the composition and style of an image or text prompt to the structure of a source video (Video to Video). Or, using nothing but words (Text to Video). It's like filming something new, without filming anything at all.",
    id: "2",
  },
  {
    title: "Pika Labs",
    showOnHome: true,
    category: ["video"],
    url: "pika-labs",
    logo: "https://pbs.twimg.com/profile_images/1729474182852030464/yBjH13CC_400x400.png",
    website: "https://pika.art/home",
    description:
      "Pika is an AI video generator for people who want to create what’s in their heads. From memes to movies, Pika follows your imagination’s lead. Pika responds to your every word – set the scene and watch it dynamically come to life. Direct Pika to turn your favorite photos, paintings, and drawings into immersive, moving scenes. Pika lets you breathe new life into existing video. Modify anything in the frame or change the style on a whim.",
    id: "3",
  },
  {
    title: "Moonvalley AI",
    showOnHome: true,
    category: ["video"],
    url: "moonvalley-ai",
    logo: "https://pbs.twimg.com/profile_images/1703947024482103296/jpU4OjFX_400x400.jpg",
    website: "https://moonvalley.ai",
    description:
      "Plan & produce studio-quality video and animation in minutes with cutting-edge deep learning video technology.",
    id: "4",
  },
  {
    title: "Stable Video Diffusion",
    showOnHome: true,
    category: ["video"],
    url: "stable-video-diffusion",
    logo: "https://pbs.twimg.com/profile_images/1641030998518374402/GiZSATqD_400x400.jpg",
    website: "https://stability.ai/stable-video",
    description:
      "Stability AI’s First Open Video Model.Stable Video Diffusion is designed to serve a wide range of video applications in fields such as media, entertainment, education, marketing. It empowers individuals to transform text and image inputs into vivid scenes and elevates concepts into live action, cinematic creations.",
    id: "5",
  },
  {
    title: "Haiper",
    showOnHome: true,
    category: ["video"],
    url: "haiper",
    logo: "https://pbs.twimg.com/profile_images/1732447136208371712/EP3d7okU_400x400.jpg",
    website: "https://haiper.ai",
    description:
      "Haiper is building video creation AI products crafted to empower individuals in creatively expressing themselves, making it effortless to deliver enjoyable, fun and relatable content across various social media platforms.",
    id: "6",
  },
  {
    title: "Zeroscope",
    showOnHome: true,
    category: ["video"],
    url: "zeroscope",
    logo: "https://d130b8xaedzatc.cloudfront.net/2023/07/Brouton--1-.webp",
    website: "https://zeroscope.replicate.dev",
    description: "Zeroscope is an open-source text-to-video model.",
    id: "7",
  },
  {
    title: "Modelscope",
    showOnHome: true,
    category: ["video"],
    url: "modelscope",
    logo: "https://styles.redditmedia.com/t5_834h81/styles/communityIcon_ispeh63975pa1.png",
    website:
      "https://huggingface.co/spaces/ali-vilab/modelscope-text-to-video-synthesis",
    description:
      "Modelscope is a tool that allows users to create videos from text using natural language processing and machine learning. It was developed by the community of developers at Hugging Face. It can be used to generate videos from text-based scripts, making it easier to create videos without the need for manual editing.",
    id: "8",
  },
  {
    title: "Topaz Video AI",
    category: ["video"],
    url: "topaz-video-ai",
    logo: "https://pbs.twimg.com/profile_images/1595846398339153922/LSh6Zhiv_400x400.jpg",
    website: "https://www.topazlabs.com",
    description:
      "Remarkable video upscaling, deinterlacing, and frame interpolation optimized for local production workflows.",
    id: "9",
  },
  {
    title: "Genmo",
    category: ["video"],
    url: "genmo",
    logo: "https://pbs.twimg.com/profile_images/1705659196354805760/4vIgnIZA_400x400.jpg",
    website: "https://www.genmo.ai",
    description:
      "Meet Genmo: a free way to make videos from text or images, powered by AI.",
    id: "10",
  },
  {
    title: "Chromox",
    category: ["video"],
    url: "chromox",
    logo: "https://pbs.twimg.com/profile_images/1737673453577424896/3sFrhi2h_400x400.jpg",
    website: "https://chromox.alkaidvision.com",
    description:
      "Text-to-video and image-to-video AI tools for art and entertainment.",
    id: "11",
  },
  {
    title: "FinalFrame",
    category: ["video"],
    url: "finalframe",
    logo: "https://pbs.twimg.com/profile_images/1764905154682208256/xyi8ctr7_400x400.jpg",
    website: "https://finalframe.ai/dashboard/login.html",
    description:
      "An indie platform to create, extend and imagine AI-generated videos.",
    id: "12",
  },
  {
    title: "PixVerse",
    category: ["video"],
    url: "pixverse",
    logo: "https://pbs.twimg.com/profile_images/1718927049833033729/Y6_3xi8B_400x400.jpg",
    website: "https://app.pixverse.ai/login",
    description:
      "A video creation platform that lets you transform ideas into visuals.",
    id: "13",
  },
  {
    title: "NeverEnds",
    category: ["video"],
    url: "neverends",
    logo: "https://pbs.twimg.com/profile_images/1720753450483695616/kYaatiDf_400x400.jpg",
    website: "https://neverends.life",
    description:
      "Image and text to video tool. NeverEnds makes it easy to generate videos!",
    id: "14",
  },
  {
    title: "StoryLineVid.io",
    category: ["video"],
    url: "storylinevid",
    logo: "https://pbs.twimg.com/profile_images/1745854556393594880/hXyAeHg2_400x400.jpg",
    website: "https://www.storylinevid.io",
    description:
      "Create stunning AI animations, images, audio and video content.",
    id: "15",
  },
  {
    title: "Morar",
    category: ["video"],
    url: "morar",
    logo: "https://pbs.twimg.com/profile_images/1734917902531215360/x_CNkBJc_400x400.png",
    website: "https://morar.ai",
    description: "GenAI video model for enterprises (small, medium and large)",
    id: "16",
  },
  {
    title: "Based Labs AI",
    category: ["video"],
    url: "based-labs-ai",
    logo: "https://pbs.twimg.com/profile_images/1755128156628131840/6P3EZ-z1_400x400.jpg",
    website: "https://www.basedlabs.ai",
    description: "The most based place to create AI images & videos",
    id: "17",
  },
  {
    title: "Snowpixel",
    category: ["video", "image", "voice_and_lip_sync", "music_and_audio"],
    url: "snowpixel",
    logo: "https://pbs.twimg.com/profile_images/1402209531568918532/P0G_mnGD_400x400.png",
    website: "https://snowpixel.app",
    description:
      "Generative AI tools for Content Creators. Generate images, videos, music, and more from just text. Train custom models with your own data for a personalized touch.",
    id: "18",
  },
  {
    title: "FullJourney",
    category: ["video", "image", "voice_and_lip_sync", "music_and_audio"],
    url: "fulljourney",
    logo: "https://pbs.twimg.com/profile_images/1669061608335613955/H0jlFuIt_400x400.jpg",
    website: "https://www.fulljourney.ai",
    description:
      "FullJourney is a complete suite of AI Creation tools at your fingertips.",
    id: "19",
  },
  {
    title: "LensGo",
    category: ["video", "image"],
    url: "lensgo",
    logo: "https://pbs.twimg.com/profile_images/1706527340816384000/4mgS6QWk_400x400.jpg",
    website: "https://lensgo.ai",
    description:
      "Image and video generation using customizable models. LensGo offers three functionalities: creating images using existing models, transforming text into videos, and allowing users to train their own models with personal images.",
    id: "20",
  },

  {
    title: "Kaiber AI",
    category: ["video", "image", "music_and_audio"],
    url: "kaiber-ai",
    logo: "https://pbs.twimg.com/profile_images/1720092227060510720/-JOlM3t-_400x400.jpg",
    website: "https://kaiber.ai",
    description:
      "Turn text, videos, photos, and music into stunning videos with our advanced AI generation engine.",
    id: "21",
  },

  {
    title: "Midjourney",
    showOnHome: true,
    category: ["image"],
    url: "midjourney",
    logo: "https://pbs.twimg.com/profile_images/1500078940299272198/quB4bgi9_400x400.jpg",
    website: "https://www.midjourney.com",
    description:
      "Exploring new mediums of thought. Expanding the imaginative powers of the human species.",
    id: "22",
  },

  {
    title: "Open AI DALLE-3",
    showOnHome: true,
    category: ["image"],
    url: "open-ai-dalle-3",
    logo: "https://play-lh.googleusercontent.com/8XCwpfWc9YkehwhrhoID6PGhs5SaSJoocS0oTBA8EsGFGLrj32oIYu5UKsIO7wdU1PQZ",
    website: "https://openai.com/dall-e-3",
    description:
      "DALL·E 3 allows you to easily translate your ideas into exceptionally accurate images.",
    id: "23",
  },

  {
    title: "Leonardo",
    category: ["image"],
    url: "leonardo",
    logo: "https://pbs.twimg.com/profile_images/1691029980078026752/5wkms-49_400x400.jpg",
    website: "https://leonardo.ai/",
    description:
      "Leonardo is a Generative AI content production suite. Create production-quality visual assets for your projects with quality, speed, and style-consistency.",
    id: "24",
  },

  {
    title: "SDXL Turbo",
    category: ["image"],
    url: "sdxl-turbo",
    logo: "https://pbs.twimg.com/profile_images/1641030998518374402/GiZSATqD_400x400.jpg",
    website: "https://stability.ai/stable-image",
    description:
      "SDXL Turbo is capable of generating image-based results as fast as you can type.",
    id: "25",
  },

  {
    title: "Topaz Photo AI",
    category: ["image"],
    url: "topaz-photo-ai",
    logo: "https://pbs.twimg.com/profile_images/1595846398339153922/LSh6Zhiv_400x400.jpg",
    website: "https://www.topazlabs.com",
    description:
      "Sharpen, remove noise, and increase the resolution of your photos with tomorrow's technology.",
    id: "26",
  },

  {
    title: "LeiaPix",
    category: ["image"],
    url: "leiapix",
    logo: "https://pbs.twimg.com/profile_images/1535057787968954368/SwhnRQ0O_400x400.jpg",
    website: "https://www.leiapix.com",
    description:
      "Turn 2D images into captivating 3D animations using LeiaPix AI.",
    id: "27",
  },

  {
    title: "Adobe Firefly",
    category: ["image"],
    url: "adobe-firefly",
    logo: "https://logowik.com/content/uploads/images/adobe-firefly-icon91.logowik.com.webp",
    website: "https://www.adobe.com/products/firefly.html",
    description:
      "Use generative AI and simple text prompts to create high-quality output — beautiful images, text effects, and fresh color palettes. Make all-new content from reference images and explore more possibilities.",
    id: "28",
  },

  {
    title: "VisionStory",
    category: ["image"],
    url: "visionstory",
    logo: "https://pbs.twimg.com/profile_images/1670705732705226753/spMy3IsR_400x400.jpg",
    website: "https://www.visionstory.ai/explore",
    description:
      "Unleashing the power of storytelling. Watch Your Imagination Unfold with innovative Generative AI!",
    id: "29",
  },

  {
    title: "Krea",
    category: ["image"],
    url: "krea",
    logo: "https://pbs.twimg.com/profile_images/1702214628422664193/8D__Vm7l_400x400.jpg",
    website: "https://www.krea.ai/home",
    description: "KREA is a design tool with AI inside.",
    id: "30",
  },

  {
    title: "DomoAI",
    category: ["image"],
    url: "toolId",
    logo: "https://pbs.twimg.com/profile_images/1743093528312610816/mjlZyPI5_400x400.jpg",
    website: "https://domoai.app",
    description:
      "DomoAI focuses on artificial intelligence research, dedicated to leading breakthroughs in thought innovation and enhancing human creative potential.",
    id: "31",
  },

  {
    title: "PromeAI",
    category: ["image"],
    url: "prome-ai",
    logo: "https://pbs.twimg.com/profile_images/1697082819875569664/UCFcEEa__400x400.jpg",
    website: "https://www.promeai.com",
    description:
      "Transform sketches into realistic photos and high-quality videos.",
    id: "32",
  },

  {
    title: "SeaArt",
    category: ["image"],
    url: "seaart",
    logo: "https://pbs.twimg.com/profile_images/1742018872600051712/J7wLthmy_400x400.jpg",
    website: "https://www.seaart.ai",
    description:
      "An online AI art creation treasure trove, exploring endless possibilities, and unleashing the wings of human imagination.",
    id: "33",
  },

  {
    title: "Ideogram",
    category: ["image"],
    url: "ideogram",
    logo: "https://pbs.twimg.com/profile_images/1729003984587804672/LSPFlzrv_400x400.jpg",
    website: "https://ideogram.ai/login",
    description: "Ideogram is an advanced text-to-image model.",
    id: "34",
  },

  {
    title: "EverArt",
    category: ["image"],
    url: "everart",
    logo: "https://pbs.twimg.com/profile_images/1717719314369789952/AmXarABn_400x400.png",
    website: "https://www.everart.ai",
    description:
      "Train AI on any product, style, or mood board. Create content like you've never seen before.",
    id: "35",
  },

  {
    title: "Magnific",
    category: ["image"],
    url: "magnific",
    logo: "https://pbs.twimg.com/profile_images/1726914935215529984/pNbqMvUP_400x400.jpg",
    website: "https://magnific.ai",
    description: "The image Upscaler & Enhancer that feels like Magic.",
    id: "36",
  },

  {
    title: "Based Labs AI",
    category: ["image"],
    url: "based-labs-ai",
    logo: "https://pbs.twimg.com/profile_images/1755128156628131840/6P3EZ-z1_400x400.jpg",
    website: "https://www.basedlabs.ai",
    description: "The most based place to create AI images & videos",
    id: "37",
  },

  {
    title: "ElevenLabs",
    showOnHome: true,
    category: ["voice_and_lip_sync"],
    url: "elevenlabs",
    logo: "https://pbs.twimg.com/profile_images/1590865996532912131/Tkgaw9L1_400x400.jpg",
    website: "https://elevenlabs.io",
    description:
      "ElevenLabs allows you to convert text to speech online for free with their AI voice generator. Create natural AI voices instantly in any language - perfect for video creators, developers, and businesses.",
    id: "38",
  },

  {
    title: "D-ID",
    showOnHome: true,
    category: ["voice_and_lip_sync"],
    url: "d-id",
    logo: "https://pbs.twimg.com/profile_images/1734574902491885568/T7QQLChm_400x400.png",
    website: "https://www.d-id.com",
    description:
      "Build interfaces that understand the needs of users and can be communicated with effectively. No typing, no clicking, just face-to-face conversation.",
    id: "39",
  },

  {
    title: "HeyGen",
    category: ["voice_and_lip_sync"],
    url: "heygen",
    logo: "https://pbs.twimg.com/profile_images/1676658221623156746/buSEhXK3_400x400.jpg",
    website: "https://www.heygen.com",
    description:
      "Effortlessly produce studio-quality videos with AI-generated avatars and voices.",
    id: "40",
  },

  {
    title: "Synclabs",
    category: ["voice_and_lip_sync"],
    url: "synclabs",
    logo: "https://media.licdn.com/dms/image/D560BAQGMBHsf6aAtTw/company-logo_200_200/0/1695162898871/synchronicity_labs_logo?e=1717632000&v=beta&t=osz8M1ewMRx_93ShmpF2dqudi40VgZyiQcCo-UTCbmA",
    website: "https://synclabs.so",
    description:
      "An API for realtime lip-sync. Visually dub any video to any audio in any language - no training required.",
    id: "41",
  },

  {
    title: "Gooey AI",
    category: ["voice_and_lip_sync"],
    url: "gooey-ai",
    logo: "https://pbs.twimg.com/profile_images/1592179160427864064/bYlfQvVI_400x400.jpg",
    website: "https://gooey.ai",
    description:
      "Discover, customize and deploy low-code AI recipes using the best of private and open source Generative AI.",
    id: "42",
  },

  {
    title: "Suno AI",
    category: ["music_and_audio"],
    url: "suno-ai",
    logo: "https://pbs.twimg.com/profile_images/1735127792776318976/BK2vuCtr_400x400.jpg",
    website: "https://www.suno.ai",
    description: "Make any song you can imagine",
    id: "43",
  },

  {
    title: "Stable Audio",
    category: ["music_and_audio"],
    url: "stable-audio",
    logo: "https://pbs.twimg.com/profile_images/1641030998518374402/GiZSATqD_400x400.jpg",
    website: "https://stability.ai/stable-audio",
    description:
      "Use generative AI to create music and sound effects. Stable Audio is Stability AI’s first product for music and sound effect generation. Users can create original audio by entering a text prompt and a duration, generating audio in high-quality, 44.1 kHz stereo. It uses a latent diffusion for audio model, trained on data from AudioSparx, a leading music library.",
    id: "44",
  },

  {
    title: "EpidemicSound",
    category: ["music_and_audio"],
    url: "epidemicsound",
    logo: "https://pbs.twimg.com/profile_images/1763208766822948864/gy01aGST_400x400.png",
    website: "https://www.epidemicsound.com",
    description:
      "Access the largest music and sound effects catalog of its kind, seen and heard over 2.5 billion times per day. With exclusive soundtracking tools and all rights included. Publish worry-free, worldwide.",
    id: "45",
  },
];
