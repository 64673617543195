import React from "react";
import { Link } from "react-router-dom";
import Carousel from "./Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { signInWithGoogle } from "../authentication";
import vX from "../images/vX.gif";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import { useState, Fragment } from "react";

export function Jumbotron({ user }) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="relative justify-center bg-gradient-to-b from-primaryGradient via-blackGradient to-black ">
        <div className="text-center mx-auto mb-12 lg:pt-32  pt-16 lg:w-8/12 w-11/12">
          <h1 className="text-white xl:text-7xl lg:text-5xl text-4xl font-semibold pb-4 lg:leading-none leading-snug ">
            Unlocking human creativity with AI
          </h1>

          <p className="lg:text-2xl text-lg text-gray mb-8 mx-auto lg:w-10/12 w-11/12">
            Nolanz is the community for filmmakers fueled by AI. Showcase your
            work, grow your audience and get inspired.
          </p>

          <div className="md:w-full flex md:flex-row flex-col md:gap-3 gap-6 text-center justify-center ">
            {user ? (
              <Link
                to="/post-video"
                className="md:mx-0 mx-auto w-52 cursor-pointer text-md rounded-full  bg-primary hover:opacity-90 border-2 border-primary text-white font-semibold px-8 py-3"
              >
                Post video
              </Link>
            ) : (
              <button
                onClick={() => setIsOpen(true)}
                className="md:mx-0 mx-auto w-52 cursor-pointer text-md rounded-full  bg-primary hover:opacity-90 border-2 border-primary text-white font-semibold px-8 py-3"
              >
                Post video
              </button>
            )}

            <Link
              to="/videos"
              className="md:mx-0 mx-auto w-52 cursor-pointer text-md rounded-full bg-white bg-opacity-10 border-white border-2 border-opacity-10 text-white font-semibold px-8 py-3"
            >
              Browse videos
            </Link>
          </div>
        </div>
        <Carousel />
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-60"
            leave="ease-in duration-200"
            leaveFrom="opacity-60"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-darkGray border-2 border-white border-opacity-10 px-6 pb-16 pt-20 align-middle  transition-all text-center">
                  <button
                    className="fixed top-6 right-6 h-10 w-10 rounded-full bg-white text-white hover:text-red-500 bg-opacity-30 hover:bg-opacity-100"
                    onClick={() => closeModal()}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-xl mt-1.5 text-center"
                    />
                  </button>

                  <FontAwesomeIcon
                    icon={faLock}
                    className="text-5xl mb-4 text-white opacity-20"
                  />
                  <h1 className="text-3xl mb-4 font-semibold text-white">
                    Sign in to continue!
                  </h1>
                  <p className="mb-6 text-md text-gray">
                    Sign in to post, like and comment videos.
                  </p>
                  <div className="md:flex-row flex-col flex gap-3 justify-center">
                    <Link
                      to="/sign-up"
                      className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-primary"
                    >
                      Sign up
                    </Link>

                    <Link
                      to="/sign-in"
                      className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-transparent hover:bg-white hover:bg-opacity-10 border border-white border-opacity-10"
                    >
                      Sign in
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
