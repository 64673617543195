import { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { analyticsLogChangedSort } from "../ganalytics";

const people = [
  { name: "Most videos", value: "videos_count", order: "desc" },
  { name: "Newest first", value: "joined", order: "desc" },
  { name: "Oldest first", value: "joined", order: "asc" },
];

export default function SelectFilmmaker({ setCurrentSort }) {
  const [selected, setSelected] = useState(people[0]);

  useEffect(() => {
    setCurrentSort(selected);
  }, [selected]);

  return (
    <div className="w-40">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button className="pl-3 pr-10 cursor-pointer text-md font-medium me-2 px-3 py-1.5 mt-2 text-opacity-30   border border-white border-opacity-40 relative w-full text-left focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2  rounded-lg  bg-lightGray grow text-white">
            <span className="block  text-white">{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <FontAwesomeIcon icon={faChevronDown} />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-4 max-h-60 w-full overflow-auto rounded-md bg-lightGray py-3 shadow-lg ring-1 ring-black/5 focus:outline-none p-3 border border-white border-opacity-40 ">
              {people.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative select-none py-2 pl-2 pr-4 cursor-pointer text-white hover:bg-primary hover:text-black rounded-lg${
                      active ? "" : ""
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {person.name}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
