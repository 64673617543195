import { Link } from "react-router-dom";
import ProfilePicture from "./ProfilePicture";

export default function CarouselCard({ image, title, id }) {
  if (image && title) {
    return (
      <div
        className="relative bg-darkGray rounded-lg h-60 w-56 gap-3 items-center  text-left z-0 bg-cover "
        style={{ backgroundImage: `url(${image})` }}
      >
        <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-gradient-to-b from-transparent to-blackGradient">
          <h1 className="absolute left-4 bottom-4 text-white font-semibold mb-0.5 truncate overflow-ellipsis z-50">
            {title}
          </h1>
        </div>
      </div>
    );
  }
}
