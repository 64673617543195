import React, { useState } from "react";
import {
  faBoxOpen,
  faComment,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import Comment from "../components/Comment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tools } from "../utils/tools";
import { useEffect } from "react";
import AddComment from "../components/AddComment";
import { getGpt } from "../firestore";
import Video from "../components/Video";
import UpvoteButton from "../components/UpvoteButton";
import { getNiceDataString } from "../utils/strings";
import { Link } from "react-router-dom";
import ProfilePicture from "../components/ProfilePicture";

export default function Gpt({ user }) {
  const url = window.location.href;
  const id = url.split("/")[url.split("/").length - 1];
  const [allTools, setAllTools] = useState();
  const [gpt, setGpt] = useState();
  const [currentComments, setCurrentComments] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    getGpt(id).then((res) => {
      console.log(res);
      setGpt(res[0]);
      setCurrentComments(res[0].data.comments);
    });
  }, []);

  useEffect(() => {
    setAllTools(tools);
  }, [tools]);

  function handleAddComment(newComments) {
    setCurrentComments(newComments.reverse());
  }

  function renderTools(tools) {
    console.log("render tools:", tools);

    return tools.map((tool) => {
      let selectedTool = allTools?.filter((t) => t.id == tool)[0];

      if (selectedTool) {
        return (
          <Link
            className="text-sm font-medium me-2 px-2 py-2 rounded-lg bg-transparent border border-white border-opacity-40 text-white hover:bg-white hover:bg-opacity-10"
            to={`/tools/${selectedTool.url}`}
          >
            {selectedTool.title}
          </Link>
        );
      }
    });
  }

  if (!gpt) {
    return (
      <>
        <div className="bg-black h-screen">
          <h1 className="text-center text-xl pt-20 text-white font-semibold">
            Loading...
          </h1>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="bg-black">
        <div className="md:w-9/12 w-11/12 mx-auto h-full pt-20 md:pb-28 pb-12 gap-3">
          <Video url={gpt?.data.url} />

          <div className="items-center md:flex md:justify-between pt-6">
            <div>
              <h1 className="text-white text-3xl font-semibold pb-1">
                {gpt?.data.title}
              </h1>

              <div className="flex gap-2 mt-2 items-center">
                <ProfilePicture user={gpt?.data?.filmmakerId} size="small" />
                <p className="text-gray">
                  Created by{" "}
                  <Link
                    className="text-white font-semibold hover:underline"
                    to={`/filmmakers/${gpt?.data.filmmakerId}`}
                  >
                    {gpt?.data.filmmakerUsername}
                  </Link>
                  , posted {getNiceDataString(gpt?.data.submittedAt)} ago.
                </p>
              </div>
            </div>

            <div className="flex gap-6 pt-4">
              <button>
                <UpvoteButton g={gpt} />
              </button>
            </div>
          </div>

          {gpt?.data.description !== "" ? (
            <div className="w-full py-4 text-left mb-2">
              <p className="text-gray">{gpt?.data.description}</p>
            </div>
          ) : null}

          <span className="text-primary bg-primary bg-opacity-25 text-xs px-2.5 py-2 rounded-lg my-1">
            {gpt?.data.genre}
          </span>

          {gpt?.data.tools.length > 0 ? (
            <div className="mt-8 transform ease-in w-full py-4 text-left border-t-2 border-white border-opacity-30">
              <h1 className="text-2xl font-semibold pt-2 pb-4 text-white">
                Tools used ({gpt?.data.tools.length})
              </h1>

              <div className="flex gap-2">{renderTools(gpt?.data?.tools)}</div>
            </div>
          ) : null}

          <div className="mt-4 transform ease-in w-full py-4 text-left border-t-2 border-white border-opacity-30">
            <h1 className="text-2xl font-semibold py-2 text-white">Comments</h1>

            <AddComment
              user={user}
              gpt={gpt}
              handleAddComment={handleAddComment}
            />

            <div className="grid gap-3">
              {currentComments?.reverse().map((c, i) => {
                return <Comment review={c} key={i} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
