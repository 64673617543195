import React, { useEffect, useState, Fragment } from "react";
import { Jumbotron } from "../components/Jumbotron";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { tools } from "../utils/tools";

import { getHottest, getMostRecent } from "../firestore";
import GPTCard from "../components/GPTCard";

import { Dialog, Transition } from "@headlessui/react";
import { signInWithGoogle } from "../authentication";
import Banner from "../components/Banner";
import { Footer } from "../components/Footer";
import ToolCard from "../components/ToolCard";

export default function Home({ user }) {
  const [mostRecentVideos, setMostRecentVideos] = useState();
  const [hottestVideos, setHottestVideos] = useState();
  const [selectedTools, setSelectedTools] = useState();

  let [isOpen, setIsOpen] = useState(false);
  // const [filmmakers, setFilmmakers] = useState();

  useEffect(() => {
    getMostRecent(6).then((res) => setMostRecentVideos(res));
    getHottest(3).then((res) => setHottestVideos(res));
    setSelectedTools(tools.filter((a) => a.showOnHome));
    // getFilmmakersWithMostVideos(4).then((res) => setFilmmakers(res));
  }, []);

  console.log(selectedTools);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="bg-black">
        <Jumbotron user={user} />
        <div className="w-11/12 mx-auto h-full pt-12 pb-16">
          <div>
            <div className="flex justify-between align-middle">
              <h1 className="text-white text-2xl font-semibold ">
                🏆 Video of the day
              </h1>

              <Link
                to="/videos"
                className="px-3 py-2 rounded-lg text-white bg-darkGray border border-white border-opacity-10 text-md hover:bg-white hover:bg-opacity-10"
              >
                View all
              </Link>
            </div>

            <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-8 mt-4">
              {hottestVideos ? (
                hottestVideos?.map((gpt, i) => (
                  <GPTCard i={i} gpt={gpt} key={i} />
                ))
              ) : (
                <p className="text-lg text-white text-center col-span-4 font-semibold mt-8">
                  Loading...
                </p>
              )}

              {hottestVideos?.length == 0 ? (
                <div className="col-span-3 border text-center border-white border-opacity-10 bg-darkGray bg-opacity-80 rounded-lg p-4 py-6 pb-6">
                  <div className="mx-auto">
                    <h1 className="text-2xl mb-2 text-white font-semibold">
                      No videos posted today!
                    </h1>
                    <p className="text-gray mb-6">
                      We reset the daily competition everyday at 12:00 AM PT.
                      Get out ahead and post your video now.
                    </p>

                    {user ? (
                      <Link
                        className="px-5 py-3 text-sm rounded-full text-white bg-primary font-semibold"
                        to="/post-video"
                      >
                        Post video
                      </Link>
                    ) : (
                      <button
                        className="px-5 py-3 text-sm rounded-full text-white bg-primary font-semibold"
                        onClick={() => setIsOpen(true)}
                      >
                        Post video
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div>
            <div className="flex justify-between align-middle mt-16">
              <h1 className="text-white text-2xl font-semibold ">
                ⚡ New videos
              </h1>

              <Link
                to="/videos"
                className="px-3 py-2 rounded-lg text-white bg-darkGray border border-white border-opacity-10 text-md hover:bg-white hover:bg-opacity-10"
              >
                View all
              </Link>
            </div>

            <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-8 mt-4">
              {mostRecentVideos ? (
                mostRecentVideos.map((gpt, i) => (
                  <GPTCard i={i} gpt={gpt} key={i} />
                ))
              ) : (
                <p className="text-lg text-white text-center col-span-4 font-semibold mt-8">
                  Loading...
                </p>
              )}
            </div>
          </div>

          <div>
            <div className="flex justify-between align-middle mt-16">
              <h1 className="text-white text-2xl font-semibold ">
                🔥 Trending tools
              </h1>

              <Link
                to="/tools"
                className="px-3 py-2 rounded-lg text-white bg-darkGray border border-white border-opacity-10 text-md hover:bg-white hover:bg-opacity-10"
              >
                View all
              </Link>
            </div>

            <div className="relative">
              {selectedTools ? (
                <>
                  <div class="absolute left-0 right-0 bottom-0 w-full h-20  bg-gradient-to-b from-transparent via-blackGradient to-black z-50"></div>

                  <div className="lg:grid-cols-4 grid grid-cols-2 gap-4 mt-4 relative">
                    {selectedTools.map((tool, i) => {
                      return <ToolCard tool={tool} key={i} />;
                    })}
                  </div>
                </>
              ) : (
                <p className="text-lg text-white text-center col-span-4 font-semibold mt-8">
                  Loading...
                </p>
              )}
            </div>
          </div>
        </div>
        <Banner />
        <Footer />
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-60"
            leave="ease-in duration-200"
            leaveFrom="opacity-60"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-darkGray border-2 border-white border-opacity-10 px-6 pb-16 pt-20 align-middle  transition-all text-center">
                  <button
                    className="fixed top-6 right-6 h-10 w-10 rounded-full bg-white text-white hover:text-red-500 bg-opacity-30 hover:bg-opacity-100"
                    onClick={() => closeModal()}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-xl mt-1.5 text-center"
                    />
                  </button>

                  <FontAwesomeIcon
                    icon={faLock}
                    className="text-5xl mb-4 text-white opacity-20"
                  />
                  <h1 className="text-3xl mb-4 font-semibold text-white">
                    Sign in to continue!
                  </h1>
                  <p className="mb-6 text-md text-gray">
                    Sign in to post, like and comment videos.
                  </p>
                  <div className="md:flex-row flex-col flex gap-3 justify-center">
                    <Link
                      to="/sign-up"
                      className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-primary"
                    >
                      Sign up
                    </Link>

                    <Link
                      to="/sign-in"
                      className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-transparent hover:bg-white hover:bg-opacity-10 border border-white border-opacity-10"
                    >
                      Sign in
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
