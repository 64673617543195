import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useUser } from "./hooks/useUser";
import Home from "./pages/Home";
import { Navbar } from "./components/Navbar";

import { Footer } from "./components/Footer";
import { AuthContextProvider } from "./firebase";
import SubmitGPT from "./pages/SubmitGPT";
import Gpt from "./pages/Gpt";
import Signup from "./pages/Signup";
import Signin from "./pages/Signin";
import Sidebar from "./components/Sidebar";
import InfoBar from "./components/InfoBar";
import Videos from "./pages/Videos";
import Filmmakers from "./pages/Filmmakers";
import { useEffect, useState } from "react";
import Filmmaker from "./pages/Filmmaker";
import Settings from "./pages/Settings";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Following from "./pages/Following";
import Tools from "./pages/Tools";
import Tool from "./pages/Tool";
import ApplyToListTool from "./pages/ApplyToListTool";
import ClaimAccount from "./pages/ClaimAccount";
import Jobs from "./pages/Jobs";
import Contests from "./pages/Contests";

function App() {
  const user = useUser();

  const [showMenu, setShowMenu] = useState(false);
  const [isScreenSizeLg, setIsScreenSizeLg] = useState();

  function handleShowMenu(value) {
    setShowMenu(value);
  }

  useEffect(() => {
    const url = window.location.href;
    const id = url.split("/");
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      setIsScreenSizeLg(true);
      setShowMenu(true);
    } else {
      setIsScreenSizeLg(false);
      setShowMenu(false);
    }

    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsScreenSizeLg(true);
        setShowMenu(true);
      } else {
        setIsScreenSizeLg(false);
        setShowMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function generateRoutes() {
    return (
      <div className="pb-12 ">
        <Navbar handleShowMenu={handleShowMenu} />

        <Routes>
          <Route exact path="/" element={<Home user={user} />} />
          <Route exact path="/videos" element={<Videos user={user} />} />
          <Route exact path="/following" element={<Following user={user} />} />
          <Route
            exact
            path="/filmmakers"
            element={<Filmmakers user={user} />}
          />

          <Route
            exact
            path="/filmmakers/:profile"
            element={<Filmmaker user={user} />}
          />

          <Route
            exact
            path="/apply-to-list-tool"
            element={<ApplyToListTool />}
          />

          <Route exact path="/tools" element={<Tools user={user} />} />
          <Route exact path="/tools/:tool" element={<Tool user={user} />} />
          <Route exact path="/claim-account" element={<ClaimAccount />} />

          <Route exact path="/post-video" element={<SubmitGPT user={user} />} />
          <Route exact path="/jobs" element={<Jobs />} />
          <Route exact path="/contests" element={<Contests />} />
          <Route exact path="/sign-in" element={<Signin user={user} />} />
          <Route exact path="/sign-up" element={<Signup user={user} />} />
          <Route exact path="/settings" element={<Settings user={user} />} />
          <Route exact path="/videos/:video" element={<Gpt user={user} />} />
          <Route exact path="/terms-of-service" element={<TermsOfService />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    );
  }

  return (
    <div className="bg-black">
      <AuthContextProvider>
        <Router>
          <div className="grid grid-cols-10 ">
            {isScreenSizeLg ? (
              <div className="col-span-2 h-screen sticky">
                <Sidebar user={user} handleShowMenu={handleShowMenu} />
              </div>
            ) : showMenu ? (
              <div className="col-span-10 h-screen sticky">
                <Sidebar user={user} handleShowMenu={handleShowMenu} />
              </div>
            ) : null}

            {isScreenSizeLg ? (
              <div className="col-span-8 overflow-scroll h-screen">
                {generateRoutes()}
              </div>
            ) : !showMenu ? (
              <div className="col-span-12 overflow-scroll h-screen">
                {generateRoutes()}
              </div>
            ) : null}
          </div>
        </Router>
      </AuthContextProvider>
    </div>
  );
}

export default App;
