import { useEffect, useState } from "react";
import { tools } from "../utils/tools";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { getMoreVideosWithTool, getVideosWithTool } from "../firestore";
import GPTCard from "../components/GPTCard";

export default function Tool() {
  const [tool, setTool] = useState();
  const [toolbox, setToolbox] = useState();
  const [videos, setVideos] = useState();

  const [currentPage, setCurrentPage] = useState(0);

  function handleLoadMore() {
    setCurrentPage((old) => old + 1);
  }

  useEffect(() => {
    if (currentPage) {
      getMoreVideosWithTool(tool.id, 4).then((res) =>
        setVideos((old) => old.concat(res))
      );
    }
  }, [currentPage]);

  useEffect(() => {
    setToolbox(tools);
  }, []);

  useEffect(() => {
    if (toolbox) {
      let url = window.location.href;
      const id = url.split("/")[url.split("/").length - 1];
      const selectedTool = toolbox.filter((a) => a.url == id)[0];
      console.log(selectedTool);
      setTool(selectedTool);
      if (selectedTool) {
        getVideosWithTool(selectedTool.id, 6).then((res) => {
          setVideos(res);
        });
      }
    }
  }, [toolbox]);

  if (tool) {
    return (
      <>
        <div className="md:w-8/12 w-11/12 mx-auto h-full py-12">
          <Link
            to="/tools"
            className="text-gray text-xl flex items-center hover:text-white"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="text-2xl mr-3" />
            <span className="font-semibold tracking-wider">Back</span>
          </Link>

          <div className="bg-darkGray border-white border-opacity-10 rounded-lg p-6 pb-8 mt-4">
            <div className="flex items-center gap-6">
              {tool.logo ? (
                <img
                  src={tool?.logo}
                  className="w-20 h-20 rounded-full bg-contain"
                />
              ) : null}

              <div>
                <h1 className="text-white text-4xl font-semibold mb-1 ">
                  {tool?.title}
                </h1>
              </div>
            </div>

            <div className="mt-4 ">
              <p className="text-gray text-lg">{tool?.description}</p>
            </div>

            {tool.website ? (
              <div className="mt-8">
                <Link
                  className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-transparent hover:bg-white hover:bg-opacity-10 border border-white border-opacity-40"
                  to={tool.website}
                  target="_blank"
                >
                  Visit website
                </Link>
              </div>
            ) : null}
          </div>

          <div className="mt-8">
            <h1 className="text-white text-2xl font-semibold">
              Videos created with {tool?.title}
            </h1>

            <div className="grid md:grid-cols-2 grid-cols-1 gap-8 mt-4 pb-8">
              {videos ? (
                videos.length == 0 ? (
                  <p className="text-gray text-lg">
                    No videos have yet been created with this tool.
                  </p>
                ) : (
                  videos.map((gpt, i) => <GPTCard i={i} gpt={gpt} key={i} />)
                )
              ) : (
                <p className="text-gray text-lg">Loading...</p>
              )}
            </div>

            {videos?.length % 6 === 0 ? (
              videos.length == 0 ? null : (
                <div className="mt-16 w-full mx-auto text-center">
                  <button
                    onClick={() => handleLoadMore()}
                    className="text-center justify-center transition duration-150 cursor-pointer text-lg  rounded-full  bg-white bg-opacity-20 text-white font-semibold px-6 py-3"
                  >
                    Load more
                  </button>
                </div>
              )
            ) : null}
          </div>

          <div className="pb-20 pt-20 text-center bg-black">
            <h1 className="md:w-8/12 w-11/12 mx-auto text-center text-white text-4xl font-bold mb-8">
              Have you created a video with {tool?.title}? Post it!
            </h1>

            <Link
              to="/post-video"
              className="text-center justify-center transition duration-150 cursor-pointer text-lg rounded-full  bg-primary text-white font-semibold px-8 py-3"
            >
              Post video
            </Link>
          </div>
        </div>
      </>
    );
  } else {
    return <h1 className="text-white font-semibold p-3">Loading...</h1>;
  }
}
