import React, { useState } from "react";
import GPTCard from "./GPTCard";
import { useEffect } from "react";
import SelectSort from "./SelectSort";
import { getGptsWithFilter, getMoreGpts } from "../firestore";
import SelectGenre from "./SelectGenre";
export default function Directory() {
  const [gpts, setGpts] = useState();
  const [currentPage, setCurrentPage] = useState(0);

  function handleLoadMore() {
    setCurrentPage((old) => old + 1);
  }

  const [currentSort, setCurrentSort] = useState({
    name: "Most upvotes",
    value: "upvote_count",
    order: "desc",
  });

  const [currentGenre, setCurrentGenre] = useState({
    value: "All",
    order: "desc",
  });

  // Retrives more gpts and appends them to current gpts
  useEffect(() => {
    if (currentPage) {
      getMoreGpts(currentPage).then((res) => setGpts((old) => old.concat(res)));
    }
  }, [currentPage]);

  console.log(gpts);

  // Retrieves the gpts for the current filters
  useEffect(() => {
    if (currentGenre.value === "All") {
      getGptsWithFilter(
        null,
        null,
        null,
        currentSort.value,
        currentSort.order,
        9
      ).then((res) => setGpts(res));
    } else {
      getGptsWithFilter(
        "genre",
        "==",
        currentGenre.value,
        currentSort.value,
        currentSort.order,
        9
      ).then((res) => {
        setGpts(res);
      });
    }
  }, [currentGenre, currentSort]);

  return (
    <>
      <div className="bg-black">
        <div className="w-11/12 mx-auto h-full">
          <div className="md:flex block gap-8 justify-between align-middle">
            <h1 className="text-white text-2xl font-semibold mt-4">
              🎞️ Browse videos
            </h1>

            <div className="flex gap-4 md:mt-0 mt-12">
              <div>
                <label class="block uppercase tracking-wider text-gray-700 text-xs font-bold mb-2 text-white">
                  Sort by
                </label>
                <SelectSort setCurrentSort={setCurrentSort} />
              </div>

              <div>
                <label class="block uppercase tracking-wider text-gray-700 text-xs font-bold mb-2 text-white">
                  Genre
                </label>
                <SelectGenre setCurrentGenre={setCurrentGenre} />
              </div>
            </div>
          </div>
          <div className="grid xl:grid-cols-3 lg:grid-cols-2  grid-cols-1 gap-8 mt-12">
            {gpts ? (
              gpts.map((gpt, i) => <GPTCard i={i} gpt={gpt} />)
            ) : (
              <p className="text-lg text-white text-center col-span-4 font-semibold mt-8">
                No videos found.
              </p>
            )}
          </div>
          {gpts?.length % 9 === 0 ? (
            <div className="mt-16 w-full mx-auto text-center">
              <button
                onClick={() => handleLoadMore()}
                className="text-center justify-center transition duration-150 cursor-pointer text-lg  rounded-full  bg-white bg-opacity-20 text-white font-semibold px-6 py-3"
              >
                Load more
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}
