import React, { useEffect, useState } from "react";
import {
  getFilmmaker,
  getMoreSubscriptions,
  getSubscriptions,
} from "../firestore";

import GPTCard from "../components/GPTCard";
import { faBoxOpen, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function Following({ user }) {
  const [videos, setVideos] = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentUser, setCurrentUser] = useState();
  function handleLoadMore() {
    setCurrentPage((old) => old + 1);
  }

  useEffect(() => {
    if (currentPage) {
      getMoreSubscriptions(6, currentUser.data.following).then((res) =>
        setVideos((old) => old.concat(res))
      );
    }
  }, [currentPage]);

  useEffect(() => {
    if (user) {
      getFilmmaker(user.id).then((r1) => {
        setCurrentUser(r1[0]);

        getSubscriptions(6, r1[0].data.following).then((r2) => {
          setVideos(r2);
        });
      });
    }
  }, [user]);

  useEffect(() => {}, []); // Empty dependency array ensures the effect runs only once

  console.log("Videos in /following: ", videos);

  if (!user) {
    return (
      <div className="bg-black">
        <div className="md:w-4/12 w-11/12 mx-auto h-full text-center mt-32 mb-20">
          <FontAwesomeIcon
            icon={faLock}
            className="text-5xl mb-4 text-white opacity-20"
          />
          <h1 className="text-3xl mb-4 font-semibold text-white">
            Sign in to continue!
          </h1>
          <p className="mb-6 text-md text-gray">
            Sign in to be able to follow your favourite filmmakers and not miss
            their latest videos.
          </p>

          <div className="md:flex-row flex-col flex gap-3 justify-center">
            <Link
              to="/sign-up"
              className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-primary"
            >
              Sign up
            </Link>

            <Link
              to="/sign-in"
              className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-transparent hover:bg-white hover:bg-opacity-10 border border-white border-opacity-10"
            >
              Sign in
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (currentUser?.data?.following.length == 0) {
    return (
      <div className="bg-black">
        <div className="md:w-4/12 w-11/12 mx-auto h-full text-center mt-32 mb-20">
          <FontAwesomeIcon
            icon={faBoxOpen}
            className="text-5xl mb-4 text-white opacity-20"
          />
          <h1 className="text-3xl mb-4 font-semibold text-white">
            You are not following anyone!
          </h1>
          <p className="mb-6 text-md text-gray">
            Nolanz is much more fun when you are following people that inspires
            you.
          </p>

          <div className="md:flex-row flex-col flex gap-3 justify-center">
            <Link
              to="/filmmakers"
              className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-primary"
            >
              Browse filmmakers
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-black">
      <div className="w-11/12 mx-auto h-full">
        <div className="flex justify-between align-middle mt-12">
          <h1 className="text-white text-2xl font-semibold ">
            ❤️ New videos from filmmakers you follow
          </h1>
        </div>

        <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-8 mt-12">
          {videos ? (
            videos.map((gpt, i) => <GPTCard i={i} gpt={gpt} key={i} />)
          ) : (
            <p className="text-lg text-white text-center col-span-4 font-semibold mt-8">
              Loading...
            </p>
          )}
        </div>

        {videos?.length % 6 === 0 ? (
          <div className="mt-16 w-full mx-auto text-center">
            <button
              onClick={() => handleLoadMore()}
              className="text-center justify-center transition duration-150 cursor-pointer text-lg  rounded-full  bg-white bg-opacity-20 text-white font-semibold px-6 py-3"
            >
              Load more
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
