import React from "react";

import { Link } from "react-router-dom";
import logo from "../images/logo_light.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export function Footer() {
  return (
    <div className="mx-auto pt-20 text-center gap-1 w-10/12 text-white">
      <img src={logo} className="w-48 mx-auto" />
      <div className="mt-4 flex gap-4 justify-center flex-wrap">
        <Link
          className="opacity-60 hover:opacity-100"
          to="mailto:hello@nolanz.ai"
        >
          hello@nolanz.ai
        </Link>

        <Link
          className="opacity-60 hover:opacity-100"
          to="https://discord.gg/fNWzQSKYNE"
          target="_blank"
        >
          Discord
        </Link>

        <Link
          className="opacity-60 hover:opacity-100"
          to="/terms-of-service"
          target="_blank"
        >
          Terms of Service
        </Link>

        <Link
          className=" opacity-60 hover:opacity-100"
          to="/privacy-policy"
          target="_blank"
        >
          Privacy Policy
        </Link>

        <Link
          className="opacity-60 hover:opacity-100"
          to="https://app.loopedin.io/nolanz/ideas"
          target="_blank"
        >
          Feedback
        </Link>
      </div>
    </div>
  );
}
