import React from "react";
import { getNiceDataString } from "../utils/strings";
import { Link } from "react-router-dom";

export default function Comment({ review }) {
  function getNiceCommentString(date) {
    return date === "Just now" ? "Just now" : getNiceDataString(date);
  }

  console.log(review);

  return (
    <div className="text-left text-white mb-6">
      <p className="text-lg font-medium">
        <Link
          className="hover:underline font-semibold "
          to={`/filmmakers/${review?.userId}`}
        >
          {review?.username}
        </Link>{" "}
        <span className="text-sm opacity-40">
          • {getNiceCommentString(review?.submittedAt)} ago
        </span>{" "}
      </p>

      <p className="text-md pt-1">{review?.text}</p>
    </div>
  );
}
