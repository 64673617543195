import { Link } from "react-router-dom";

export default function Banner() {
  return (
    <div className="pb-20 pt-20 text-center bg-black">
      <h1 className="md:w-8/12 w-11/12 mx-auto text-center text-white text-5xl font-bold mb-12">
        Ready to grow your audience?
      </h1>

      <Link
        to="/post-video"
        className="text-center justify-center transition duration-150 cursor-pointer text-lg rounded-full  bg-primary text-white font-semibold px-8 py-3"
      >
        Post your video
      </Link>
    </div>
  );
}
