import CarouselCard from "./CarouselCard";

export default function Carousel() {
  const data = [
    {
      title: "Jesse Koivukoski",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2FsoU3ZBjasCRXE92YtCpAmz6hCiI3?alt=media&token=72476a39-25b5-4db3-960d-c6fe3247811f",
      id: "soU3ZBjasCRXE92YtCpAmz6hCiI3",
    },

    {
      title: "YOKOHAMI",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2FgVpQIBehznOu6UVVz8cK4Bjn3Lz1?alt=media&token=f136ec08-fb04-4ac2-9404-186a29019fb0",
      id: "gVpQIBehznOu6UVVz8cK4Bjn3Lz1",
    },

    {
      title: "Aze Alter",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2FZEF9KX5YcsS5WWWeHvdFty9qGA43?alt=media&token=11e865df-89a8-464c-af78-402f9f8576bf",
      id: "ZEF9KX5YcsS5WWWeHvdFty9qGA43",
    },
    {
      title: "Ai.ded.it",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2Fq0H2dPZIIQPZtF3576PZfXDW36Y2?alt=media&token=da27807b-82ee-47b5-a06d-683b98afdb64",
      id: "q0H2dPZIIQPZtF3576PZfXDW36Y2",
    },
    {
      title: "Padphone Lee",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2FYAZoPxY2O0eaBvKqfFMlzX1DGqY2?alt=media&token=5d9f81d2-cd57-43e8-a181-361c48fca106",
      id: "YAZoPxY2O0eaBvKqfFMlzX1DGqY2",
    },
    {
      title: "AI FilmCraft by GioM",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2FhI3PUcKCJ9PwGqdgAVyJbCFOXzm2?alt=media&token=76154942-440e-47dc-b9f7-7452b4856c12",
      id: "hI3PUcKCJ9PwGqdgAVyJbCFOXzm2",
    },
    {
      title: "The Butcher's Brain",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2FQjT8ASSSGfN6GrgbUQJkt0rqPb43?alt=media&token=e5a05038-15e2-4855-b1ef-c8ba04903395",
      id: "QjT8ASSSGfN6GrgbUQJkt0rqPb43",
    },
    {
      title: "Uncanny Harry",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2FiH8t0xsxSBPRyYA3ysnEo4wI4Tf2?alt=media&token=b4dfa695-c8a7-4c6b-b80a-d74c5adbfd7c",
      id: "iH8t0xsxSBPRyYA3ysnEo4wI4Tf2",
    },
    {
      title: "Max AI",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2Fb9jMg8JFLoYsSCXahvX1Dtludi63?alt=media&token=dcd8739b-4e55-48ac-850e-f235a8304637",
      id: "b9jMg8JFLoYsSCXahvX1Dtludi63",
    },
    {
      title: "Joe Ata",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2FWL7JeFXT6Rb1LswBBUZFMHT5Xbz2?alt=media&token=039dff6a-1990-4169-a4a4-c70fa164a432",
      id: "WL7JeFXT6Rb1LswBBUZFMHT5Xbz2",
    },

    {
      title: "The Ai Daddy",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2FSb0wFW3myBSzu9TC27c5iUGH8k42?alt=media&token=bb86ef48-886f-4dbe-871f-10ef68dea63b",
      id: "Sb0wFW3myBSzu9TC27c5iUGH8k42",
    },

    {
      title: "Brett Stuart",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2FwLvUw9NycZOBIcPiVnVmuwV0nzi2?alt=media&token=70b81410-55a5-4dd0-919f-7b7666cee69e",
      id: "wLvUw9NycZOBIcPiVnVmuwV0nzi2",
    },

    {
      title: "Jeff Synthesized",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2Fcobv11PBxCM69aq2zS8sx0JuEQy2?alt=media&token=808ac009-250c-46d3-8207-b179c56cec50",
      id: "cobv11PBxCM69aq2zS8sx0JuEQy2",
    },

    {
      title: "Machine Mythos",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2FPS14fucWTuRAFFlpWTALy4fk6VA3?alt=media&token=092e91e3-89b8-4f28-a5c1-5d74b416a7de",
      id: "PS14fucWTuRAFFlpWTALy4fk6VA3",
    },

    {
      title: "AI CineVerse Studios",
      image:
        "https://firebasestorage.googleapis.com/v0/b/nolan-20982.appspot.com/o/profile_pictures%2F5TZrZg7qF8bCuhvpB787MTq4Hdk1?alt=media&token=7dc9a5b3-561c-41ec-a76f-187667744674",
      id: "5TZrZg7qF8bCuhvpB787MTq4Hdk1",
    },
  ];

  let reversedGpts = [...data].reverse();

  return (
    <div className="w-full pt-20 pb-4">
      <h1 className="text-center block uppercase tracking-wide text-white opacity-30 font-bold mb-6 px-6 text-md">
        Powering the next Christopher Nolans
      </h1>

      <div className="w-full mx-auto overflow-x-hidden text-center gap-12 ">
        <div className="flex gap-6 animate-[wiggle_60s_linear_infinite] -translate-x-1/2 flex-row w-fit mb-6 ml-20 ">
          {data.map((gpt) => (
            <CarouselCard
              title={gpt.title}
              creator={gpt.creator}
              image={gpt.image}
              id={gpt.id}
            />
          ))}
        </div>
        {/* <div className="flex gap-6 animate-[wiggle_60s_linear_infinite] -translate-x-1/2 flex-row w-fit mb-6  ml-60 ">
          {reversedGpts.map((gpt) => (
            <CarouselCard
              title={gpt.title}
              creator={gpt.creator}
              image={gpt.image}
              id={gpt.id}
            />
          ))}
        </div> */}
      </div>
    </div>
  );
}
