import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function SidebarItem({ title, to, icon, active, external }) {
  return (
    <Link to={to} className="text-md" target={external ? "_blank" : ""}>
      <div
        className={`my-2 flex items-center gap-2 px-3 py-2 rounded-lg hover:bg-darkGray hover:text-white cursor-pointer  ${
          active ? "text-white bg-darkGray " : "text-gray"
        }`}
      >
        <div className="w-8">
          {icon ? (
            <FontAwesomeIcon
              icon={icon}
              className={`text-xl mr-1 ${active ? "text-primary" : ""}`}
            />
          ) : null}
        </div>
        {title}
      </div>
    </Link>
  );
}
