import React, { useEffect, useState } from "react";
import ToolCard from "../components/ToolCard";
import { tools } from "../utils/tools";
import { Link } from "react-router-dom";

export default function Tools() {
  const [toolbox, setToolbox] = useState([]);

  useEffect(() => {
    setToolbox(tools);
  }, []);

  return (
    <>
      <div className="bg-black mt-10">
        <div className="w-11/12 mx-auto h-full">
          <div className="py-20">
            <h1 className="text-5xl text-white font-semibold">Browse tools</h1>
            <p className="text-lg pt-3 text-gray mb-10 lg:w-1/2 w-full">
              There are plenty of great tools that can help you unlock your
              creativity and we have compiled them for you here.
            </p>
            <Link
              className="cursor-pointer text-center text-md  rounded-full font-semibold px-6 py-3 bg-primary text-white mt-4"
              to="/apply-to-list-tool"
            >
              Apply to be listed
            </Link>
          </div>

          <div className="my-10">
            <h1 className="text-white text-2xl font-semibold mt-4">
              🎞️ Video Generators
            </h1>

            <div className="grid md:grid-cols-4 grid-cols-2 gap-8 mt-6 text-center">
              {toolbox
                .filter((a) => a.category.includes("video"))
                .map((tool, i) => (
                  <ToolCard tool={tool} key={i} />
                ))}
            </div>
          </div>

          <div className="my-10">
            <h1 className="text-white text-2xl font-semibold mt-4">
              🎨 Image Generators
            </h1>

            <div className="grid md:grid-cols-4 grid-cols-2 gap-8 mt-6 text-center">
              {toolbox
                .filter((a) => a.category.includes("image"))
                .map((tool, i) => (
                  <ToolCard tool={tool} key={i} />
                ))}
            </div>
          </div>

          <div className="my-10">
            <h1 className="text-white text-2xl font-semibold mt-4">
              💬 Voice and Lip Sync Generators
            </h1>

            <div className="grid md:grid-cols-4 grid-cols-2 gap-8 mt-6 text-center">
              {toolbox
                .filter((a) => a.category.includes("voice_and_lip_sync"))
                .map((tool, i) => (
                  <ToolCard tool={tool} key={i} />
                ))}
            </div>
          </div>

          <div className="my-10">
            <h1 className="text-white text-2xl font-semibold mt-4">
              🥁 Music and Audio Generators
            </h1>

            <div className="grid md:grid-cols-4 grid-cols-2 gap-8 mt-6 text-center">
              {toolbox
                .filter((a) => a.category.includes("music_and_audio"))
                .map((tool, i) => (
                  <ToolCard tool={tool} key={i} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
