import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { tools } from "../utils/tools";
import { getFilmmaker, isUsernameTaken } from "../firestore";
import {
  faCheckCircle,
  faLock,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { doc, getFirestore, updateDoc } from "firebase/firestore";

import {
  getStorage,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "@firebase/storage";

export default function Settings({ user }) {
  console.log("user: ", user);
  const storage = getStorage();
  const [selectedTools, setSelectedtools] = useState([]);
  const [query, setQuery] = useState("");
  const [allTools, setAllTools] = useState([]);
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);
  const [filmmaker, setFilmmaker] = useState();
  const [username, setUsername] = useState("");
  const [unavailableUsername, setUnavailableUsername] = useState(false);
  const [oldUsername, setOldUsername] = useState("");
  const [bio, setBio] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [instagram, setInstagram] = useState("");
  const [oldPicture, setOldPicture] = useState();
  const [newPicture, setNewPicture] = useState(null);

  console.log("selected tools: ", selectedTools);

  function handleTechnologyUpdate(input) {
    if (selectedTools.includes(input)) {
      setSelectedtools(selectedTools.filter((item) => item !== input));
    } else {
      setSelectedtools([...selectedTools, input]);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    setAllTools(tools);

    if (user) {
      getFilmmaker(user.id).then((res) => {
        setFilmmaker(res[0]);
        setUsername(res[0].data.username);
        setOldUsername(res[0].data.username);
        setBio(res[0].data.bio);
        setInstagram(res[0].data.instagram);
        setYoutube(res[0].data.youtube);
        setTwitter(res[0].data.twitter);
        setSelectedtools(res[0].data.selectedTools);
      });

      const storage = getStorage();
      getDownloadURL(ref(storage, `/profile_pictures/${user.id}`))
        .then((url) => setOldPicture(url))
        .catch((error) => console.log(error));
    }
  }, [user]);

  function checkValidEntry() {
    let validTwitter = true;
    let validInstagram = true;
    let validYoutube = true;

    if (
      twitter.length > 0 &&
      !twitter.includes("twitter.com") &&
      !twitter.includes("x.com")
    ) {
      validTwitter = false;
    } else {
      validTwitter = true;
    }

    if (instagram.length > 0 && !instagram.includes("instagram.com")) {
      validInstagram = false;
    }

    if (youtube.length > 0 && !youtube.includes("youtu")) {
      validYoutube = false;
    }

    if (
      username.length > 0 &&
      validTwitter &&
      validInstagram &&
      validYoutube &&
      bio.length <= 1001
    ) {
      return true;
    } else {
      return false;
    }
  }

  function handleFileChange(e) {
    e.preventDefault();
    console.log("Added a profile picture!");
    setNewPicture(e.target.files[0]);
  }

  const uploadFile = () => {
    const storageRef = ref(storage, `/profile_pictures/${user.id}`);
    uploadBytesResumable(storageRef, newPicture);
  };

  function handleSubmit() {
    console.log(oldUsername, username);

    if (checkValidEntry()) {
      if (newPicture) {
        uploadFile();
      }
      updateDoc(doc(getFirestore(), "filmmakers", filmmaker.id), {
        username: username,
        bio: bio,
        instagram: instagram,
        twitter: twitter,
        youtube: youtube,
        selectedTools: selectedTools,
      }).then(() => {
        setSuccessfulSubmit(true);
        console.log("Successfully updated: ", filmmaker.id);
      });
    }
  }

  if (!user) {
    return (
      <div className="mx-auto mt-20 w-full max-w-md transform rounded-2xl bg-darkGray border-2 border-white border-opacity-10 px-6 pb-16 pt-20 align-middle  transition-all text-center">
        <FontAwesomeIcon
          icon={faLock}
          className="text-5xl mb-4 text-white opacity-20"
        />
        <h1 className="text-3xl mb-4 font-semibold text-white">
          Sign in to edit your profile!
        </h1>
        <p className="mb-6 text-md text-gray">
          Sign in to post, like and comment videos.
        </p>
        <div className="md:flex-row flex-col flex gap-3 justify-center">
          <Link
            to="/sign-up"
            className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-primary"
          >
            Sign up
          </Link>

          <Link
            to="/sign-in"
            className="cursor-pointer text-center text-md  rounded-full  text-white font-semibold px-6 py-3 bg-transparent hover:bg-white hover:bg-opacity-10 border border-white border-opacity-10"
          >
            Sign in
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-black min-h-screen">
        {!successfulSubmit ? (
          <div className="xl:w-8/12 w-11/12  mx-auto h-full py-12 gap-3 pb-20">
            <div className="rounded-lg bg-darkGray py-6 px-6">
              <div className="flex flex-col gap-6">
                <div className="col-span-12 flex flex-col gap-3">
                  <p className="block text-white text-xl font-semibold">
                    Avatar
                  </p>

                  <div className="flex gap-3 relative mt-4 mb-2 items-center">
                    {oldPicture || newPicture ? (
                      <img
                        alt="logo"
                        className="md:h-24 md:w-24 w-16 h-16 md:mr-6 mr-4 rounded-full border-2 border-transparent"
                        src={
                          newPicture
                            ? URL.createObjectURL(newPicture)
                            : oldPicture
                        }
                      />
                    ) : (
                      <div className="md:h-24 md:w-24 w-16 h-16 md:mr-6 mr-4 border-2 border-gray border-dashed rounded-full text-center">
                        <FontAwesomeIcon
                          icon={faUser}
                          className="text-center text-4xl text-gray pt-6"
                        />
                      </div>
                    )}

                    <div>
                      <input
                        type="file"
                        className="text-gray"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-12 flex flex-col gap-3">
                  <p className="block text-white text-xl font-semibold">
                    Username
                  </p>

                  <input
                    className="p-3 rounded-lg focus:outline-none border bg-lightGray  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoComplete="off"
                  />
                </div>

                {unavailableUsername ? (
                  <p className="text-md text-red-500">
                    Username already taken.
                  </p>
                ) : null}

                <div className="flex flex-col gap-2">
                  <p className="text-white text-xl font-semibold flex justify-between mb-2">
                    <span>Bio</span>
                    <span className="text-gray text-sm font-semibold items-baseline">
                      ({bio.length} / 1000)
                    </span>
                  </p>

                  <textarea
                    className="p-3 rounded-lg focus:outline-none bg-lightGray border  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                    placeholder="Description"
                    rows={4}
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    autoComplete="off"
                  />
                </div>

                <div className="col-span-12 flex flex-col gap-3">
                  <p className="block text-white text-xl font-semibold">
                    Social accounts
                  </p>

                  <div className="flex gap-3">
                    <div className="h-12 w-8 text-center">
                      <FontAwesomeIcon
                        icon={faTwitter}
                        className="text-2xl mt-3 text-gray"
                      />
                    </div>

                    <input
                      className="p-3 rounded-lg grow focus:outline-none border bg-lightGray  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                      placeholder="Link to your Twitter"
                      value={twitter}
                      onChange={(e) => setTwitter(e.target.value)}
                      autoComplete="off"
                    />
                  </div>

                  <div className="flex gap-3">
                    <div className="h-12 w-8 text-center">
                      <FontAwesomeIcon
                        icon={faYoutube}
                        className="text-2xl mt-3 text-gray"
                      />
                    </div>

                    <input
                      className="p-3 rounded-lg grow focus:outline-none border bg-lightGray  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                      placeholder="Link to your Youtube"
                      value={youtube}
                      onChange={(e) => setYoutube(e.target.value)}
                      autoComplete="off"
                    />
                  </div>

                  <div className="flex gap-3">
                    <div className="h-12 w-8 text-center">
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="text-2xl mt-3 text-gray"
                      />
                    </div>

                    <input
                      className="p-3 rounded-lg grow focus:outline-none border bg-lightGray  border-white border-opacity-40 focus:border-primary text-white text-md placeholder-white placeholder-opacity-40"
                      placeholder="Link to your Instagram"
                      value={instagram}
                      onChange={(e) => setInstagram(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                </div>

                {/* <div className="col-span-12 flex flex-col gap-3">
                  <p className="block text-white text-xl font-semibold">
                    Specialties
                  </p>

                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex items-center gap-3 p-3 rounded-lg grow focus:outline-none bg-lightGray border-white border border-opacity-40  hover:border-primary hover:bg-primary text-white text-md placeholder-white placeholder-opacity-40">
                      <FontAwesomeIcon
                        icon={true ? faCheckSquare : faSquare}
                        className="text-xl"
                      />
                      Specialty
                    </div>
                  </div>
                </div> */}

                <div className="flex flex-col gap-2">
                  <p className="text-white text-md font-medium flex justify-between">
                    <p className="block text-white text-xl font-semibold">
                      Primary tools
                    </p>
                    <span className="text-gray text-sm font-semibold items-baseline">
                      ({selectedTools?.length} selected)
                    </span>
                  </p>

                  <div className="flex flex-wrap gap-x-2 gap-y-4 mt-2 border-white border-opacity-40 rounded-lg">
                    <div className="flex justify-between w-full gap-6 border-b-2 border-white border-opacity-40 pb-3">
                      <div className="flex items-center gap-3 grow">
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="text-gray text-lg"
                        />
                        <input
                          className="w-full  bg-transparent  text-white focus:outline-none text-md placeholder-gray grow"
                          placeholder="Search tools..."
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                          autoComplete="off"
                        />
                      </div>

                      <p className="text-gray w-20 text-right">
                        <span className="font-semibold text-white">
                          {
                            allTools?.filter((tool) => {
                              return tool.title
                                .toLowerCase()
                                .includes(query.toLowerCase());
                            }).length
                          }
                        </span>{" "}
                        results
                      </p>
                    </div>

                    <div className="xl:max-h-none max-h-48 overflow-y-auto flex flex-wrap gap-x-2 gap-y-4">
                      {allTools ? (
                        allTools
                          .filter((tool) => {
                            return tool.title
                              .toLowerCase()
                              .includes(query.toLowerCase());
                          })
                          .map((c) => {
                            return (
                              <div
                                className={`cursor-pointer text-sm font-medium me-2 px-3 py-2 rounded-lg flex gap-2 items-center ${
                                  selectedTools?.includes(c.id)
                                    ? "bg-primary text-white border-primary border"
                                    : "bg-lightGray text-white border border-white border-opacity-40 hover:bg-primary hover:border-primary"
                                }`}
                                onClick={() => handleTechnologyUpdate(c.id)}
                              >
                                <img
                                  src={c.logo}
                                  className="w-5 h-5 rounded-full"
                                />
                                {c.title}
                              </div>
                            );
                          })
                      ) : (
                        <h1>Loading tools...</h1>
                      )}
                    </div>
                  </div>
                </div>

                {checkValidEntry() ? (
                  <button
                    className="w-80 mx-auto mt-2 text-center px-5 py-2 border border-transparent font-semibold text-white  bg-primary text-lg rounded-full"
                    onClick={() => handleSubmit()}
                  >
                    Save profile
                  </button>
                ) : (
                  <button className="w-80 mx-auto cursor-not-allowed mt-2  text-center px-5 py-2 border border-transparent font-semibold text-white bg-white bg-opacity-10 text-lg rounded-full">
                    Save profile
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="lg:4/12 md:w-6/12 w-11/12 mx-auto h-full pt-20 gap-3 pb-20">
            <div className="bg-darkGray rounded-lg py-6 px-6  border border-white border-opacity-10 text-center pb-1">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-7xl mb-4 text-green"
              />
              <h1 className="text-2xl font-semibold mb-3 text-white">
                Success! 🎉
              </h1>
              <p className="text-lg text-gray mb-8">
                Your profile was successfully updated! See your new changes on
                the URL below.
              </p>

              <Link to={`/filmmakers/${filmmaker.id}`}>
                <div className="hover:bg-white hover:bg-opacity-10 w-full text-left p-3 rounded-lg focus:outline-none bg-transparent border break-all border-white border-opacity-40 text-white text-md placeholder-white placeholder-opacity-40">
                  www.nolanz.ai/filmmakers/{filmmaker.id}
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
