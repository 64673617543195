import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faLock, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { signInWithGoogle } from "../authentication";
import { useAuthState } from "../firebase";
import { getGpt } from "../firestore";
import { toggleUpvoteGpt, userHasUpvoted } from "../firestore";
import { Link } from "react-router-dom";

export default function UpvoteButton({ g }) {
  const [gpt, setGpt] = useState(g);
  const [count, setCount] = useState();
  const { user } = useAuthState();
  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setCount(g.data.upvote_count);
  }, [g]);

  function closeModal() {
    setIsOpen(false);
  }

  const handleUpvote = async (e, input) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (user) {
        getGpt(input.id).then((res) => {
          let tmp = res[0];

          let tmpUpvotes = input.data.upvotes;

          const hasUserUpvoted = userHasUpvoted(tmpUpvotes, user.uid);

          toggleUpvoteGpt(input, user.uid, hasUserUpvoted)
            .then((result) => {
              tmp.data.upvotes = result;
              tmp.data.upvote_count = result.length;
              return tmp;
            })
            .then((tmp) => {
              console.log("new video?:", tmp);
              setGpt(tmp);
              setCount(tmp.data.upvote_count);
            });
        });
      } else {
        setIsOpen(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return gpt ? (
    <>
      <button
        className="h-12 w-12 rounded-full bg-white text-white hover:text-red-500 bg-opacity-30 hover:bg-opacity-100 mb-2"
        onClick={(e) => handleUpvote(e, gpt)}
      >
        <FontAwesomeIcon
          icon={faHeart}
          className={`text-2xl mt-2  ${
            userHasUpvoted(gpt.data.upvotes, user?.uid)
              ? "text-red-500"
              : "text-white hover:text-red-500"
          }`}
        />
      </button>

      <p className="text-md font-semibold text-white">{count}</p>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-60"
            leave="ease-in duration-200"
            leaveFrom="opacity-60"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-darkGray border-2 border-white border-opacity-10 px-6 pb-16 pt-20 align-middle  transition-all text-center">
                  <button
                    className="fixed top-6 right-6 h-10 w-10 rounded-full bg-white text-white hover:text-red-500 bg-opacity-30 hover:bg-opacity-100"
                    onClick={() => closeModal()}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-xl mt-1.5 text-center"
                    />
                  </button>

                  <FontAwesomeIcon
                    icon={faLock}
                    className="text-5xl mb-4 text-white opacity-20"
                  />
                  <h1 className="text-3xl mb-4 font-semibold text-white">
                    Sign in to continue!
                  </h1>
                  <p className="mb-6 text-md text-gray">
                    Sign in to post, like and comment videos.
                  </p>
                  <div className="flex gap-3 mx-auto justify-center">
                    <Link
                      to="/sign-in"
                      className="flex gap-2 text-center justify-center transition duration-150 cursor-pointer text-lg  rounded-full  bg-primary text-white font-semibold px-8 py-3 "
                    >
                      Sign in
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  ) : (
    <div></div>
  );
}
