import { Link } from "react-router-dom";
import ProfilePicture from "./ProfilePicture";

export default function ProfileThumbnail({ filmmaker }) {
  console.log(filmmaker);
  return (
    <Link
      to={`/filmmakers/${filmmaker.id}`}
      className="bg-darkGray rounded-xl px-2 py-6 border-2 border-white border-opacity-10 hover:border-primary flex flex-col justify-center"
    >
      <div className="">
        <ProfilePicture user={filmmaker.id} center={true} />
      </div>

      <div className="mt-4">
        <h1 className="text-white text-xl font-semibold mb-0.5 truncate overflow-ellipsis">
          {filmmaker.data.username}
        </h1>
        <p className="text-gray text-md mt-1">
          <span className="text-white font-semibold">
            {filmmaker.data.followers.length}
          </span>{" "}
          followers,{" "}
          <span className="text-white font-semibold">
            {filmmaker.data.videos_count}
          </span>{" "}
          videos.
        </p>
      </div>
    </Link>
  );
}
