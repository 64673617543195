export default function ClaimAccount() {
  return (
    <div className="bg-black mt-10">
      <div className="md:w-6/12 w-11/12 mx-auto h-full text-center">
        <div className="py-20">
          <h1 className="text-4xl mb-3 text-white font-semibold">
            Want to claim your account? Contact us!
          </h1>
          <p className="text-gray text-xl">
            To launch Nolanz we have manually created profiles for a couple of
            selected filmmakers whose work we got inspired by. So if thats you,
            congrats!
            <br />
            <br /> If one of these profiles belong to you, we would love to
            onboard you to the platform and give you access to your account.
            Please send an email to{" "}
            <span className="text-primary">hello@nolanz.ai</span> and make sure
            to include a link to the Nolanz profile that you would like to
            claim.
            <br />
            <br />
            Best, The Nolanz team
          </p>
        </div>
      </div>
    </div>
  );
}
