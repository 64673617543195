export var GENRE_INFO = [
  { id: 0, title: "All", icon: "🌐", selected: true },
  { id: 1, title: "Action", icon: "💥", selected: false },
  { id: 2, title: "Comedy", icon: "😂", selected: false },
  { id: 3, title: "Drama", icon: "🎭", selected: false },
  { id: 4, title: "Fantasy", icon: "🧙‍♂️", selected: false },
  { id: 5, title: "Horror", icon: "🧟‍♂️", selected: false },
  { id: 6, title: "Mystery", icon: "🔍", selected: false },
  { id: 7, title: "Romance", icon: "💑", selected: false },
  { id: 8, title: "Sci-Fi", icon: "🚀", selected: false },
  { id: 9, title: "Documentary", icon: "🎥", selected: false },
  { id: 10, title: "Thriller", icon: "😱", selected: false },
  { id: 11, title: "Family", icon: "👨‍👩‍👧‍👦", selected: false },
  { id: 12, title: "Music", icon: "🎵", selected: false },
  { id: 13, title: "Western", icon: "🤠", selected: false },
  { id: 14, title: "War", icon: "⚔️", selected: false },
  { id: 15, title: "Anime", icon: "🌸", selected: false },
  { id: 16, title: "Ads", icon: "🛍️", selected: false },
];

export const GENRE_TITLES = [
  "Select...",
  "Action",
  "Adventure",
  "Ads",
  "Animals",
  "Animated film",
  "Anime",
  "Art",
  "Christmas",
  "Comedy",
  "Conflict",
  "Crime",
  "Culture",
  "Dark",
  "Deep",
  "Documentary",
  "Drama",
  "Dystopian",
  "Emotional",
  "Family",
  "Fantasy",
  "Fashion",
  "Halloween",
  "History",
  "Holiday",
  "Horror",
  "Intellectual",
  "Music",
  "Mystery",
  "Parody",
  "Poetic",
  "Promotion",
  "Religion",
  "Romance",
  "Sad",
  "Sci-Fi",
  "Space",
  "Strange",
  "Super Heroes",
  "Supportive",
  "Thriller",
  "War",
  "Western",
  "Other",
];
