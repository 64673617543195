import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UpvoteButton from "./UpvoteButton";
import { getNiceDataString } from "../utils/strings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faHeart } from "@fortawesome/free-solid-svg-icons";
import ProfilePicture from "./ProfilePicture";
import { tools } from "../utils/tools";

export default function GPTCard({ gpt, i }) {
  const [allTools, setAllTools] = useState();
  function youtubeUrlToId(url) {
    const patterns = [
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?.*?v=([a-zA-Z0-9_-]{11})/,
      /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})/,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/.*?\?v=([a-zA-Z0-9_-]{11})/,
    ];

    for (let i = 0; i < patterns.length; i++) {
      const match = url.match(patterns[i]);
      if (match && match[1]) {
        return match[1];
      }
    }

    return null;
  }

  useEffect(() => {
    setAllTools(tools);
  }, [tools]);

  function renderTools(tools) {
    let tmp = [];

    if (tools.length > 2) {
      tmp = tools.slice(0, 2);
    } else {
      tmp = tools;
    }

    return tmp.map((tool) => {
      let selectedTool = allTools?.filter((t) => t.id == tool)[0];

      if (selectedTool) {
        return (
          <span className="text-xs font-medium me-2 px-2 py-1 rounded-lg bg-transparent border border-white border-opacity-10 text-white  ">
            {selectedTool.title}
          </span>
        );
      }
    });
  }

  if (!gpt) {
    return <h1 className="text-white">Loading...</h1>;
  } else {
    return (
      <Link to={`/videos/${gpt?.id}`}>
        <div className="w-full">
          <img
            className="w-full rounded-xl border-2 border-white border-opacity-10 hover:border-primary"
            src={`https://img.youtube.com/vi/${youtubeUrlToId(
              gpt?.data?.url
            )}/mqdefault.jpg`}
          />

          <div className="mt-4">
            <p className="text-lg text-white pb-1 overflow-hidden whitespace-nowrap overflow-ellipsis">
              {gpt?.data?.title}
            </p>

            <div className="gap-2 text-sm">
              <div className="flex justify-between text-gray items-center mt-2">
                <div className="flex gap-2 items-center">
                  <ProfilePicture user={gpt?.data?.filmmakerId} size="small" />
                  <p className="text-sm">{gpt?.data?.filmmakerUsername}</p>
                </div>
                <div className="flex gap-2 text-md">
                  <div>
                    <FontAwesomeIcon icon={faHeart} /> {gpt?.data?.upvote_count}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faComment} />{" "}
                    {gpt?.data?.comments?.length}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 flex flex-wrap gap-x-0.5 gap-y-2">
            <span className="text-xs font-medium me-2 px-2 py-1 rounded-lg bg-transparent border border-white border-opacity-10 text-white ">
              {gpt?.data?.genre}
            </span>
            {renderTools(gpt?.data?.tools)}
            {gpt?.data?.tools.length > 2 ? (
              <span className="text-xs font-medium me-2 px-2 py-1 rounded-lg bg-transparent border border-white border-opacity-10 text-white ">
                + {gpt?.data?.tools.length - 2}
              </span>
            ) : null}
          </div>
        </div>
      </Link>
    );
  }
}
